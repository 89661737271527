export const TipoUsuario = {
  CORRETOR: 'Corretor',
  GERENTE: 'Gerente',
  SDR: 'SDR',
  DIRETOR: 'Diretor',
  MASTER: 'Master',
};

export const tipoUsuarioOrdem = [
  'Corretor',
  'Gerente',
  'SDR',
  'Diretor',
  'Master',
];
