export const decisaoAtendimentosOptions = [
  {
    key: 'nada-apenas-inativar',
    label: 'Nada, apenas inativar usuário',
    finalMessage: 'Não sofrerão alteração',
  },
  {
    key: 'enviar-para-perdido',
    label: 'Enviar para PERDIDO',
    finalMessage: 'Enviados para PERDIDO',
  },
  {
    key: 'distribuir-para-membros',
    label: 'Distribuir para os usuários selecionados',
    finalMessage:
      'Distribuidos entre os Corretores e/ou Grupo de Corretores selecionados',
  },
];

export const decisaoImoveisOptions = [
  {
    key: 'nada-apenas-inativar',
    label: 'Nada, apenas inativar usuário',
    finalMessage: 'Não sofrerão alteração',
  },
  {
    key: 'retirar-da-oferta',
    label: 'Retirar da oferta',
    finalMessage: 'Removidos das ofertas',
  },
  {
    key: 'distribuir-para-membros',
    label: 'Distribuir para os usuários selecionados',
    finalMessage:
      'Distribuidos entre os Corretores e/ou Grupo de Corretores selecionados',
  },
];
