import React from 'react';
import {Select} from 'antd';

import {filterOption, mapOptionsGroup} from './utils';

const {Option, OptGroup} = Select;

const SelectDropdownCorretores = ({
  placeholder,
  onChange,
  defaultValue,
  empresaIds,
  showInative,
  isLoading,
  corretores,
  mode,
  ...props
}) => {
  const optionsGroup = mapOptionsGroup(
    showInative ? corretores : corretores.filter((c) => !c?.excluido) ?? [],
  );

  return isLoading ? (
    <Select
      placeholder={placeholder || 'Selecione o corretor...'}
      allowClear
      defaultValue={defaultValue}
      style={{width: '100%'}}
      disabled
    />
  ) : (
    <div className="w-100">
      <Select
        allowClear
        mode={mode}
        defaultValue={defaultValue}
        showSearch
        optionFilterProp="children"
        filterOption={filterOption}
        placeholder={placeholder || 'Selecione o corretor...'}
        style={{width: '100%'}}
        onChange={(value) => onChange && onChange(value)}
        {...props}>
        {optionsGroup.map((item) => (
          <OptGroup key={item.title} value={item.title} label={item.title}>
            {item.options.map((option) => {
              return (
                <Option
                  key={option.id}
                  value={option.id}
                  options={{
                    type: item.key,
                  }}>
                  {option.label}
                </Option>
              );
            })}
          </OptGroup>
        ))}
      </Select>
    </div>
  );
};

export default SelectDropdownCorretores;
