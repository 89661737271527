import React, {Fragment, useEffect, useState} from 'react';
import {Card, CardHeader, CardBody, Row, Col, CardFooter} from 'reactstrap';
import {Select, Tag, Upload, Tooltip, Input, Progress} from 'antd';
import {Icon} from 'semantic-ui-react';
import {
  LoadingOutlined,
  PlusOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import ModalGenerico from '~/components/ModalGenerico';

import Notify from '~/shared/Notify';
import Page from '~/components/Page';

import url from '~/infra/urls';
import api from '~/services/api';
import history from '~/services/history';
import Button from '~/shared/Button';
import planilhaExemplo from '~/assets/img/planilhaExemplo.png';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import * as signalR from '@microsoft/signalr';
import './styles.css';

const {Option, OptGroup} = Select;

const TipoMembro = {
  CORRETOR: 'corretor',
  GRUPO_CORRETORES: 'grupo-corretores',
};

/**
 * HelperTooltipMembros
 */
const HelperTooltipMembros = () => (
  <Tooltip
    placement="top"
    title={
      <Fragment>
        <p>
          Neste campo você pode selecionar um ou mais corretores ou grupo de
          corretores.
        </p>
        <p>
          Para identificar o <b>"Grupo de Corretores"</b> facilmente, bastar
          observar a cor do item.
        </p>
        <small>
          Obs: <br />
          <p>
            Ao selecionar um "Grupo de Corretores", todos os corretores
            pertencentes ao grupo mesmo que não estejam listados no campo
            abaixo, terão acesso a oferta ativa.
          </p>
        </small>
        <small>
          <p>
            Caso o corretor esteja listado abaixo e também pertença a um grupo,
            o grupo prevalecerá garantindo acesso a oferta ativa.
          </p>
        </small>
        <p>
          <p>
            Grupo de Corretores: <Tag color="#1d1f8c">Azul</Tag>
          </p>
          <p>
            Corretor:{' '}
            <Tag color="lightgray" style={{color: 'black'}}>
              Cinza
            </Tag>
          </p>
        </p>
      </Fragment>
    }>
    <QuestionCircleOutlined
      style={{
        fontSize: '16px',
        fontWeight: 'bold',
        marginLeft: '5px',
        cursor: 'pointer',
        color: '#1d1f8c',
      }}
    />
  </Tooltip>
);

const mapOptionsGroup = (corretores, grupoCorretores) => [
  {
    key: TipoMembro.CORRETOR,
    label: <strong>Corretores</strong>,
    title: 'Corretores',
    options: corretores
      .filter((item) => !item.excluido) // remove os corretores excluídos
      .map((item) => ({
        id: item.id,
        label: item.nome,
        key: TipoMembro.CORRETOR,
      })),
  },
  {
    key: TipoMembro.GRUPO_CORRETORES,
    label: <strong>Grupo de Corretores</strong>,
    title: 'Grupo de Corretores',
    options: grupoCorretores.map((item) => ({
      id: item.id,
      label: item.grupo,
      key: TipoMembro.GRUPO_CORRETORES,
    })),
  },
];

const SelectCorretoresOrGrupoCorretoresInput = ({
  label,
  placeholder,
  enableHelperTooltip,
  enableTagRender,
  onChange,
  ...props
}) => {
  const {data: grupoCorretores} = useGrupoCorretores();
  const {data: corretores} = useCorretorDropdown();
  const optionsGroup = mapOptionsGroup(corretores, grupoCorretores);
  const whichGroupItBelongs = (id) =>
    optionsGroup.find(
      (item) => item.options.find((option) => option.id === id) !== undefined,
    );
  const filterOption = (input, option) => {
    if (Array.isArray(option.options)) {
      return false;
    }

    return (
      option.children
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .indexOf(
          input
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, ''),
        ) >= 0
    );
  };

  const tagRender = (props) => {
    const belongsTo = whichGroupItBelongs(props.value);
    return (
      <Tag
        style={{lineHeight: '22px', fontSize: '14px'}}
        color={belongsTo?.key === TipoMembro.CORRETOR ? null : '#1d1f8c'}
        closable={props.closable}
        onClose={props.onClose}
        placeholder={
          belongsTo?.key === TipoMembro.CORRETOR
            ? 'Corretor'
            : 'Grupo de Corretores'
        }>
        {props.label}
      </Tag>
    );
  };

  return (
    <div className="p-2 mb-4">
      <p className="heading-small text-muted mb-2">
        <small>{label}</small>
        {enableHelperTooltip && <HelperTooltipMembros />}
      </p>
      <Select
        style={{width: '100%'}}
        placeholder={` ${placeholder}`}
        mode="multiple"
        tagRender={enableTagRender ? tagRender : null}
        filterOption={filterOption}
        showArrow
        allowClear
        onChange={(_, options) => {
          if (onChange)
            onChange(
              options.map((option) => ({
                id: option.key,
                tipo: option.options.type,
              })),
            );
        }}
        {...props}>
        {optionsGroup.map((item) => (
          <OptGroup key={item.title} value={item.title} label={item.title}>
            {item.options.map((option) => (
              <Option
                key={option.id}
                value={option.id}
                options={{
                  type: item.key,
                }}>
                {option.label}
              </Option>
            ))}
          </OptGroup>
        ))}
      </Select>
    </div>
  );
};

function CadastroOfertaAtiva(props) {
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState();
  const [nome, setNome] = useState();
  const [membros, setMembros] = useState([]);
  const [openModalMaisInfo, setOpenModalMaisInfo] = useState(false);
  const usuarioId = localStorage.getItem('icaseUsuarioId');
  const [uploadProgress, setUploadProgress] = useState(
    parseInt(localStorage.getItem('progress')) || 0,
  );
  const [identificador, setIdentificador] = useState();

  useEffect(() => {
    const fetchProgress = async () => {
      const response = await api.get(`proposta-ativa/progress`);
      if (response.data.progress === 100) response.data.progress = 0;
      setUploadProgress(response.data.progress);
      localStorage.setItem('progress', response.data.progress);

      const connection = new signalR.HubConnectionBuilder()
        .withUrl(
          `${process.env.REACT_APP_BASE_URL.replace('/api', '')}/uploadHub`,
          {withCredentials: false},
        )
        .build();

      connection.on('ReceiveProgress', (usserReceive, progress) => {
        if (usserReceive === usuarioId) {
          if (progress === 100) progress = 0;

          setUploadProgress(progress);
          localStorage.setItem('progress', progress);
        }
      });

      connection
        .start()
        .catch((err) => console.error('SignalR connection error: ', err));

      return () => {
        connection.stop();
      };
    };

    fetchProgress();
  }, [usuarioId]);

  useEffect(() => {
    const identificador = props?.location?.state?.identificador;
    setIdentificador(identificador);
  }, [props.location.state.identificador]);

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/oferta-ativa/${identificador}`);
    } else {
      history.push('/app/oferta-ativa');
    }
  }

  async function salvar() {
    if (!nome) {
      Notify('error', '', 'Favor informe um Nome para a Oferta Ativa.');
      return;
    }

    if (fileData) {
      if (!membros || membros.length === 0) {
        Notify(
          'error',
          '',
          'Favor selecionar um membro (corretor ou grupo de corretores) para a oferta ativa.',
        );
        return;
      }

      setLoading(true);

      api
        .post(
          url.OFERTA_ATIVA.CADASTRAR(),
          {
            nome: nome,
            arquivo: fileData,
            ...membros.reduce((acc, item, index) => {
              acc[`membros[${index}]`] = item;
              return acc;
            }, {}),
            fornecedor: 1,
          },
          {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          },
        )
        .then(() => {
          setLoading(false);
          Notify('success', '', 'Arquivo carregado com sucesso!');
          voltar();
        })
        .catch((err) => {
          setLoading(false);
          const {error} = err.response.data;
          if (error) {
            for (const item in error) {
              Notify('error', '', error[item]);
            }
          }
        });
    } else {
      Notify('error', '', 'Favor carregar um arquivo');
    }
  }

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined
          style={{fontSize: '24px', color: '#08c', fontWeight: 'bold'}}
        />
      ) : (
        <PlusOutlined
          style={{fontSize: '24px', color: '#08c', fontWeight: 'bold'}}
        />
      )}
      <div style={{marginTop: 8}}>
        {fileData?.name ? (
          <strong style={{color: 'red'}}>{fileData?.name}</strong>
        ) : (
          'Upload'
        )}
      </div>
    </div>
  );

  function handleUploadfile(info) {
    setFileData(info.file);
  }

  return (
    <Fragment>
      <Page>
        <Row>
          <Col>
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Oferta ativa</h5>
                <p className="category">Importação de Leads</p>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12" md="6">
                    <div className="p-2 mb-4">
                      <p className="heading-small text-muted mb-2">
                        <small>Nome</small>
                      </p>
                      <Input
                        disabled={loading || uploadProgress > 0}
                        style={{width: '100%'}}
                        placeholder=" Descrição da oferta ativa"
                        onChange={(event) => setNome(event.target.value)}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12" md="6">
                    <SelectCorretoresOrGrupoCorretoresInput
                      disabled={loading || uploadProgress > 0}
                      style={{width: '100%'}}
                      label="Membros (Corretores ou Grupo de Corretores)"
                      enableHelperTooltip
                      enableTagRender
                      placeholder=" Membros (Corretores ou Grupo de Corretores)"
                      onChange={(value) => setMembros(value)}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <div className="p-2 mb-4">
                      <p>Clique abaixo para enviar a planilha de oferta</p>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs="1">
                    <div className="p-2 mb-4">
                      <p>
                        <Tooltip
                          placement="top"
                          title="Modelo de planilha de dados">
                          <Button
                            onClick={() => setOpenModalMaisInfo(true)}
                            icon
                            labelPosition="left"
                            size="tiny"
                            fluid
                            color="violet"
                            disabled={loading}>
                            <QuestionCircleOutlined
                              style={{
                                fontSize: '24px',
                                color: '#FFF',
                                fontWeight: 'bold',
                              }}
                            />
                          </Button>
                        </Tooltip>
                      </p>
                    </div>
                  </Col>
                </Row>
                {uploadProgress > 0 && (
                  <Row>
                    <Col>
                      <div className="p-2 mb-4">
                        <p>
                          <strong>Progresso de envio do arquivo:</strong>
                        </p>
                        <Progress
                          percent={uploadProgress}
                          status="active"
                          size="default"
                          strokeColor={{from: '#108ee9', to: '#87d068'}}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
                {uploadProgress === 0 && (
                  <Row>
                    <Col xs="12">
                      <div className="p-2 mb-4">
                        <Upload
                          name="avatar"
                          listType="picture-card"
                          className="avatar-uploader"
                          showUploadList={false}
                          customRequest={handleUploadfile}>
                          {uploadButton}
                        </Upload>
                      </div>
                    </Col>
                  </Row>
                )}
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="12" md="4">
                    <Button
                      disabled={loading || uploadProgress > 0}
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      color="violet"
                      onClick={salvar}>
                      <Icon name="check" />
                      Cadastrar
                    </Button>
                  </Col>
                  <Col xs="12" md="4">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      onClick={voltar}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Page>
      <ModalGenerico
        open={openModalMaisInfo}
        close={() => setOpenModalMaisInfo(false)}>
        <Fragment>
          <Page>
            <Row>
              <Col xs="12">
                <p className="p-2 mb-4">
                  Modelo de planilha de dados para importação de oferta ativa:
                </p>
                <img
                  className="mr-3 h-50 align-self-end"
                  src={planilhaExemplo}
                  alt="..."
                />
                <p className="p-2 mt-4">
                  Obs: O formato do arquivo deve ser <strong>XLSX</strong>
                </p>
              </Col>
            </Row>
          </Page>
        </Fragment>
      </ModalGenerico>
    </Fragment>
  );
}

export default CadastroOfertaAtiva;
