import React, {useEffect} from 'react';
import {Row, Col} from 'reactstrap';
import {Form, Select, Spin} from 'antd';

import {tipoDistribuicao} from '~/infra/resources/strings/enums/enumDistribuicaoLeads';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import {LoadingOutlined} from '@ant-design/icons';
import useTipoUsuario from '~/actions/TipoUsuario/useTipoUsuario';
import useEmpresasGrupoDropdown from '~/actions/DropDown/useEmpresasGrupoDropdown';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import SkeletonInput from 'antd/lib/skeleton/Input';

const {Option} = Select;

function FormaDistribuicao({
  setFinalValues,
  finalValues,
  isLoading,
  tipoDistribuicaoId,
  setTipoDistribuicaoId,
  setUsuariosOptions,
  usuariosOptions,
}) {
  const latitudeCompanyData = JSON.parse(
    localStorage.getItem('latitudeCompanyData'),
  );
  const isMatriz = !!!latitudeCompanyData.matrizId;
  const listEmpresas = JSON.parse(localStorage.getItem('icaseEmpresas')) || [];

  const {data: dataGrupoCorretores, onLoad: onLoadGruposCorretores} =
    useGrupoCorretores(null, null, null, true);
  const {data: dataEmpresas, isLoading: isLoadingCompany} =
    useEmpresasGrupoDropdown();
  const {data: tipoUsuarios} = useTipoUsuario();
  const {
    data: dataCorretores,
    onLoad: onLoadCorretores,
    isLoading: isLoadingCorretores,
  } = useCorretorDropdown({
    abortPreload: true,
    withInactiveInName: true,
    withTypeInName: true,
  });

  function onSearchCorretor(value) {
    if (value.length > 3) {
      onLoadCorretores({nome: value, empresas: finalValues?.empresasId});
    }
  }

  const filterOption = (input, option) =>
    option?.children
      ?.toLowerCase()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '')
      ?.indexOf(
        input
          ?.toLowerCase()
          ?.normalize('NFD')
          ?.replace(/[\u0300-\u036f]/g, ''),
      ) >= 0;

  useEffect(() => {
    onLoadGruposCorretores({empresas: finalValues?.empresasId});
    onLoadCorretores({empresas: finalValues?.empresasId});
  }, [finalValues.empresasId, onLoadCorretores, onLoadGruposCorretores]);

  useEffect(() => {
    const newUsers = [];
    for (let index = 0; index < dataCorretores?.length; index++) {
      const element = dataCorretores[index];
      if (!usuariosOptions.some((u) => u.id === element.id))
        newUsers.push(element);
    }

    setUsuariosOptions([...usuariosOptions, ...newUsers]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCorretores]);

  return (
    <Row className="p-4">
      {isMatriz || listEmpresas.length > 1 ? (
        isLoadingCompany ? (
          <SkeletonInput />
        ) : (
          <Col xs="12" md="12">
            <p className="heading-small text-muted mb-2">
              <small>Empresas</small>
            </p>
            <Form.Item
              className="my-2"
              name="empresasId"
              rules={[
                {
                  required: true,
                  message: 'Informe uma ou mais Empresas',
                },
              ]}>
              <Select
                showSearch
                filterOption={(input, option) =>
                  option?.children
                    ?.toLowerCase()
                    ?.normalize('NFD')
                    ?.replace(/[\u0300-\u036f]/g, '')
                    ?.indexOf(
                      input
                        ?.toLowerCase()
                        ?.normalize('NFD')
                        ?.replace(/[\u0300-\u036f]/g, ''),
                    ) >= 0
                }
                onChange={(value, obj) =>
                  setFinalValues({
                    ...finalValues,
                    ...{
                      empresasDesc: obj?.map((c) => c.children),
                    },
                    empresasId: value,
                  })
                }
                placeholder=" Informe uma ou mais Empresas"
                mode="multiple"
                allowClear>
                {dataEmpresas.map((item) => (
                  <Option key={item.id} disabled={item?.disabled}>
                    {item.nome}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        )
      ) : null}
      <Col xs="12" md="12">
        <p className="heading-small text-muted mb-2">
          <small>Tipo de Distribuição</small>
        </p>
        <Form.Item
          className="my-2"
          name="tipoDistribuicaoId"
          rules={[
            {
              required: true,
              message: 'Informe um Tipo de Distribuição',
            },
          ]}>
          <Select
            showSearch
            filterOption={filterOption}
            onChange={(value, obj) => {
              setFinalValues({
                ...finalValues,
                ...{tipoDistribuicaoDesc: obj?.children},
              });
              setTipoDistribuicaoId(value);
            }}
            allowClear>
            {tipoDistribuicao.map((item) => (
              <Option disabled={item.disabled} key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Col>

      {tipoDistribuicaoId === '5' && (
        <Col xs="12" md="12">
          <p className="heading-small text-muted mb-2">
            <small>Equipe(s)</small>
          </p>
          <Form.Item
            className="my-2"
            name="equipesId"
            rules={[
              {
                required: true,
                message: 'Informe uma ou mais equipes',
              },
            ]}>
            <Select
              showSearch
              filterOption={filterOption}
              placeholder=" Informe uma ou mais equipes"
              mode="multiple"
              onChange={(value, obj) =>
                setFinalValues({
                  ...finalValues,
                  ...{equipesDesc: obj?.map((c) => c.children)},
                })
              }
              allowClear>
              {dataGrupoCorretores.map((item) => (
                <Option disabled={item.disabled} key={item.id}>
                  {item.grupo}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}

      {tipoDistribuicaoId === '6' && (
        <Col xs="12" md="12">
          <p className="heading-small text-muted mb-2">
            <small>Usuário(s)</small>
          </p>
          <Form.Item
            className="my-2"
            name="usuariosId"
            rules={[
              {
                required: true,
                message: 'Informe um ou mais usuários',
              },
            ]}>
            <Select
              defaultActiveFirstOption
              showSearch
              optionFilterProp="children"
              onChange={(value, obj) =>
                setFinalValues({
                  ...finalValues,
                  ...{usuariosDesc: obj?.map((c) => c.children)},
                })
              }
              filterOption={filterOption}
              placeholder=" Informe um ou mais usuários"
              mode="multiple"
              disabled={isLoading}
              notFoundContent={
                isLoadingCorretores ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        spin
                        style={{marginRight: 5, fontSize: 16}}
                      />
                    }>
                    Carregando...
                  </Spin>
                ) : null
              }
              onSearch={onSearchCorretor}>
              {!isLoadingCorretores &&
                usuariosOptions.map((item) => (
                  <Option key={item.id} disabled={item.isSeparator}>
                    {item?.nome?.toString()}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Col>
      )}

      {tipoDistribuicaoId === '7' && (
        <Col xs="12" md="12">
          <p className="heading-small text-muted mb-2">
            <small>Tipo de Usuários</small>
          </p>
          <Form.Item
            className="my-2"
            name="tiposUsuarioId"
            rules={[
              {
                required: true,
                message: 'Informe um ou mais Tipos de Usuários',
              },
            ]}>
            <Select
              showSearch
              filterOption={filterOption}
              onChange={(value, obj) =>
                setFinalValues({
                  ...finalValues,
                  ...{tiposUsuarioDesc: obj?.map((c) => c.children)},
                })
              }
              placeholder=" Informe um ou mais Tipos de Usuários"
              mode="multiple"
              allowClear>
              {tipoUsuarios.map((item) => (
                <Option key={item.id}>{item.name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      )}
    </Row>
  );
}

export default FormaDistribuicao;
