import {useEffect, useState, useCallback, useMemo} from 'react';
import api from '~/services/api';
import url from '~/infra/urls';
import debounce from 'lodash.debounce';

let controller;
let canceledRequest = false;
export default function useCorretorDropdown(
  {abortPreload, defaultParams, withTypeInName, withInactiveInName} = {
    abortPreload: false,
    defaultParams: {empresas: [], withUserType: false, withAtendimento: false},
    withTypeInName: true,
    withInactiveInName: true,
  },
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [quantidade, setQuantidade] = useState(null);

  const cancelRequest = () => {
    if (controller) {
      canceledRequest = true;
      controller.abort();
    }
  };

  const onLoad = useCallback(
    async (params = {}) => {
      setIsLoading(true);

      const param = {...defaultParams, ...params};

      let filter = '';

      if (param?.empresas?.length > 0) {
        filter =
          '?' +
          param.empresas
            .map((guid) => `&empresasId=${encodeURIComponent(guid)}`)
            .join('&');
      }

      if (param?.nome) {
        filter +=
          filter.length > 0 ? `&nome=${param.nome}` : `?nome=${param.nome}`;
      }

      if (param?.id) {
        filter += filter.length > 0 ? `&id=${param.id}` : `?id=${param.id}`;
      }

      if (param?.withUserType) {
        filter +=
          filter.length > 0 ? `&withUserType=true` : `?withUserType=true`;
      }

      if (param?.withAtendimento) {
        filter +=
          filter.length > 0 ? `&withAtendimento=true` : `?withAtendimento=true`;
      }

      try {
        const res = await api.get(
          `${url.DROPDOWN.CORRETORES_DROPDOWN()}${filter}`,
        );
        canceledRequest = false;

        let corretoresAtivos =
          res?.data
            .filter((corretor) => !corretor.excluido)
            .map((corretor) => {
              if (withTypeInName && param?.withUserType) {
                corretor.nome = `${corretor.tipoUsuario} - ${corretor.nome}`;
              }
              return corretor;
            }) ?? [];

        let corretoresInativos =
          res?.data
            .filter((corretor) => corretor.excluido)
            .map((corretor) => {
              if (withInactiveInName) {
                corretor.nome = `${corretor.nome} (inativo)`;
              }
              return corretor;
            }) ?? [];

        if (corretoresInativos.length > 0) {
          corretoresInativos.unshift({
            isSeparator: true,
            id: 'separadorInativo',
            nome: 'Inativos',
          });
        }

        const dataToSet = [...corretoresAtivos, ...corretoresInativos];

        setData(dataToSet ?? []);
        setQuantidade(res?.data?.length || 0);
      } catch (e) {
        setData([]);
      } finally {
        if (!canceledRequest) {
          setIsLoading(false);
        }
      }
    },
    [defaultParams, withTypeInName, withInactiveInName],
  );

  useEffect(() => {
    if (abortPreload) return;

    onLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [abortPreload]);

  const debouncedChangeHandler = useMemo(() => debounce(onLoad, 500), [onLoad]);

  useEffect(() => {
    return () => {
      debouncedChangeHandler.cancel();
      cancelRequest();
    };
  }, [debouncedChangeHandler]);

  return {
    data,
    quantidade,
    isLoading,
    onLoad: debouncedChangeHandler,
  };
}
