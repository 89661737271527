/* eslint-disable no-unused-vars */
import React, {Fragment} from 'react';

import useDashboardGerente from './hooks/use-dashboard-gerente';

import {Select, DatePicker} from 'antd';
import {Card, CardHeader, Col, Row} from 'reactstrap';

import CardWithBadge from './components/charts/CardWithBadge/CardWithBadge';
import SalesRanking from './components/charts/SalesRanking/SalesRanking';
import ActiveOfferRank from './components/charts/ActiveOfferRank/ActiveOfferRank';
import SalesFunnel from './components/charts/SalesFunnel/SalesFunnel';
import QualityOfService from './components/charts/QualityOfService/QualityOfService';
import DaysWithoutSelling from './components/charts/DaysWithoutSelling/DaysWithoutSelling';
import LeadOrigin from './components/charts/LeadOrigin/LeadOrigin';
import RealEstate from './components/charts/RealEstate/RealEstate';
import RankingCaptors from './components/charts/RankingCaptors/RankingCaptors';
import BirthdayOfTheDay from './components/charts/BirthdayOfTheDay/BirthdayOfTheDay';
import useGrupoCorretores from '~/actions/DropDown/useGrupoCorretores';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
import OfertaAtivaCorretorWidget from '../DashboardCorretor/components/OfertaAtivaCorretorWidget';

const {Option} = Select;
const {RangePicker} = DatePicker;

export default function DashboardGerente({isDiretorOrMaster}) {
  const {
    filters,
    compareWithOptions,
    handleChangeFilter,
    totalByTypeData,
    showRangePicker,
    setFilters,
  } = useDashboardGerente();

  const {data: dataCorretores} = useCorretorDropdown();
  const {data: dataGrupoCorretores} = useGrupoCorretores();

  const brokerGroupOptions = [
    {
      label: 'TODOS',
      value: null,
    },
  ].concat(
    dataGrupoCorretores?.map((brokerGroup) => ({
      label: brokerGroup?.grupo,
      value: brokerGroup.id,
    })),
  );

  const brokerOptions = [
    {
      label: 'TODOS',
      value: null,
    },
  ].concat(
    dataCorretores?.map((broker) => ({
      label: broker?.nome,
      value: broker.id,
      disabled: broker?.isSeparator,
    })),
  );

  function handleSelectCustomDate(dates) {
    const isEmpty = dates?.every((date) => date === '');
    setFilters({
      ...filters,
      mainPeriod: 'data-personalizada',
      initialDate: isEmpty ? null : dates[0],
      finalDate: isEmpty ? null : dates[1],
    });
  }

  return (
    <Fragment>
      <Row>
        <Col>
          <Card className="p-2">
            <CardHeader>
              <Row>
                <Col xs="12" md="2">
                  <div className="my-2">
                    <h5 className="title">Dashboard</h5>
                    <p className="category">Dados e Métricas</p>
                  </div>
                </Col>
                <Col>
                  <div className="my-2">
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <span className="text-red-500">Período principal</span>
                      <Select
                        defaultValue={'30d'}
                        placeholder="Selecione um período"
                        trigger={['click']}
                        onChange={(value) =>
                          handleChangeFilter('mainPeriod', value)
                        }>
                        <Option value="15d">Últimos 15 dias</Option>
                        <Option value="30d">Últimos 30 dias</Option>
                        <Option value="data-personalizada">
                          Data personalizada
                        </Option>
                      </Select>
                    </label>
                  </div>
                </Col>
                {showRangePicker && (
                  <Col>
                    <div className="my-2">
                      <label
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <span className="text-red-500">
                          Selecione data inicial e final
                        </span>
                        <RangePicker
                          onChange={(_, stringDates) => {
                            handleSelectCustomDate(stringDates);
                          }}
                        />
                      </label>
                    </div>
                  </Col>
                )}
                <Col>
                  <div className="my-2">
                    <label
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                      <span className="text-red-500">Comparar com</span>
                      <Select
                        defaultValue={'previous'}
                        options={compareWithOptions}
                        placeholder="Selecione uma opção"
                        onChange={(value) =>
                          handleChangeFilter('compareWith', value)
                        }
                      />
                    </label>
                  </div>
                </Col>
                <Col>
                  {isDiretorOrMaster ? (
                    <div className="my-2">
                      <label
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <span>Equipe de Corretores</span>
                        <Select
                          defaultValue={
                            dataGrupoCorretores?.length === 1
                              ? dataGrupoCorretores[0].id
                              : null
                          }
                          options={brokerGroupOptions}
                          onChange={(value) =>
                            handleChangeFilter('brokerGroup', value)
                          }
                          placeholder="Selecione uma Equipe de Corretores"
                        />
                      </label>
                    </div>
                  ) : (
                    <div className="my-2">
                      <label
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}>
                        <span>Corretor</span>
                        <Select
                          defaultActiveFirstOption
                          onChange={(value) =>
                            handleChangeFilter('broker', value)
                          }
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .normalize('NFD')
                              .replace(/[\u0300-\u036f]/g, '')
                              .indexOf(
                                input
                                  .toLowerCase()
                                  .normalize('NFD')
                                  .replace(/[\u0300-\u036f]/g, ''),
                              ) >= 0
                          }
                          placeholder="Selecione um Corretor">
                          {brokerOptions.map((item) => (
                            <Option key={item.value} disabled={item.disabled}>
                              {item?.label?.toString()}
                            </Option>
                          ))}
                        </Select>
                      </label>
                    </div>
                  )}
                </Col>
                <Col>
                  <div className="mt-4 pt-2">
                    <OfertaAtivaCorretorWidget />
                  </div>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <CardWithBadge
            titulo="Vendas"
            tipo={totalByTypeData?.resultVenda?.tipo}
            quantidade={totalByTypeData?.resultVenda?.qtdTotal ?? 0}
            flutuacao={totalByTypeData?.resultVenda?.flutuacao}
            tituloFlutuacao={`Comparado ao período anterior: ${Intl.NumberFormat(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ).format(totalByTypeData?.resultVenda?.valorTotalAnterior || 0)}`}
            valor={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalByTypeData?.resultVenda?.valorTotal || 0)}
          />
        </Col>

        <Col>
          <CardWithBadge
            titulo="Locações"
            tipo={totalByTypeData?.resultLocacao?.tipo}
            quantidade={totalByTypeData?.resultLocacao?.qtdTotal ?? 0}
            flutuacao={totalByTypeData?.resultLocacao?.flutuacao}
            tituloFlutuacao={`Comparado ao período anterior: ${Intl.NumberFormat(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ).format(
              totalByTypeData?.resultComissao?.valorTotalAnterior || 0,
            )}`}
            valor={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalByTypeData?.resultLocacao?.valorTotal || 0)}
          />
        </Col>

        <Col>
          <CardWithBadge
            titulo="Comissão"
            tipo={totalByTypeData?.resultComissao?.tipo}
            flutuacao={totalByTypeData?.resultComissao?.flutuacao}
            tituloFlutuacao={`Comparado ao período anterior: ${Intl.NumberFormat(
              'pt-BR',
              {
                style: 'currency',
                currency: 'BRL',
              },
            ).format(
              totalByTypeData?.resultComissao?.valorTotalAnterior || 0,
            )}`}
            valor={Intl.NumberFormat('pt-BR', {
              style: 'currency',
              currency: 'BRL',
            }).format(totalByTypeData?.resultComissao?.valorTotal || 0)}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <SalesRanking filters={filters} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ActiveOfferRank filters={filters} />
        </Col>
        <Col>
          <SalesFunnel filters={filters} />
        </Col>
      </Row>
      <Row>
        <Col>
          <QualityOfService filters={filters} />
        </Col>
      </Row>
      <Row>
        <Col>
          <DaysWithoutSelling filters={filters} />
        </Col>
        <Col>
          <LeadOrigin filters={filters} />
        </Col>
      </Row>
      <Row>
        <Col>
          <RealEstate filters={filters} />
        </Col>
      </Row>
      <Row>
        <Col>
          <RankingCaptors filters={filters} />
        </Col>
        <Col>
          <BirthdayOfTheDay />
        </Col>
      </Row>
    </Fragment>
  );
}
