export const tipoDistribuicao = [
  {label: 'Para o captador do imóvel', value: 1},
// {label: 'Enviar para o receptor de lead da empresa', value: 2},
  {label: 'Para escala de plantão', value: 3},
  {label: 'Para todos corretores', value: 4},
  {label: 'Para equipes selecionadas', value: 5},
  {label: 'Para usuários selecionados', value: 6},
  {label: 'Para tipos de usuários selecionados', value: 7},
 // {label: 'Distribuir entre empresas', value: 8},
];

export const camposFacebook = [
  {label: 'form_name', value: 'form_name'},
  {label: 'page_name', value: 'page_name'},
  {label: 'campaign_name', value: 'campaign_name'},
];


export const camposLatitude = [
  {label: 'Nome', value: 'Nome'},
  {label: 'Celular', value: 'Celular'},
  {label: 'Telefone', value: 'Telefone'},
  {label: 'E-mail', value: 'Email'},
  {label: 'Observações', value: 'Mensagem'},
  {label: 'Cód. Imóvel', value: 'CodImovel'},
];