import { SmileOutlined } from '@ant-design/icons';
import { Tag, Timeline } from 'antd';
import React from 'react';

const ResumoDistribuicao = ({ values, fazRedistribuicao }) => {
    const { nome,
        tipoDistribuicaoId,
        tipoDistribuicaoDesc,
        paginaFacebookDesc,
        formulariosFacebookDesc,
        todosFormsFacebook,
        formFacebookDesconsiderarDesc,
        tiposMidiaDesc,
        tiposUsuarioDesc,
        equipesDesc,
        empresasDesc,
        usuariosDesc,
        tipoRedistribuicaoId,
        tipoRedistribuicaoDesc,
        prazoRedistribuicao,
        qtdRedistribuicao,
        equipesRedistribuicaoDesc,
        empresasRedistribuicaoDesc,
        usuariosRedistribuicaoDesc,
        tiposUsuarioRedistribuicaoDesc,
        campanhasRdDesc
    } = values;

    return (
        <Timeline className="p-4">
            <Timeline.Item color="green">Nome: <b>{nome}</b></Timeline.Item>

            <Timeline.Item color="green">
                <p>Tipos de Mídias selecionados: </p>
                {tiposMidiaDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>

           {campanhasRdDesc && campanhasRdDesc.length > 0 && <Timeline.Item color="green">
                <p>Campanhas RD Station selecionados: </p>
                {campanhasRdDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {!!paginaFacebookDesc && <Timeline.Item color="green">Página Facebook: <b>{paginaFacebookDesc}</b></Timeline.Item>}

            {!todosFormsFacebook && formulariosFacebookDesc && formulariosFacebookDesc.length > 0 && <Timeline.Item color="green">
                <p>Formulários Facebook: </p>
                {formulariosFacebookDesc?.map((e, index) => <Tag key={index}>{e.desc}</Tag>)}
            </Timeline.Item>}

            {!!todosFormsFacebook && <Timeline.Item color="green">Formulários Facebook: <b>Todos</b></Timeline.Item>}

            {formFacebookDesconsiderarDesc && formFacebookDesconsiderarDesc.length > 0 && <Timeline.Item color="green">
                <p>Formulários Facebook (Desconsiderar): </p>
                {formFacebookDesconsiderarDesc?.map((e, index) => <Tag key={index}>{e.desc}</Tag>)}
            </Timeline.Item>}

            {empresasDesc && empresasDesc.length > 0 && <Timeline.Item>
                <p>Empresas selecionadas: </p>
                {empresasDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            <Timeline.Item>Tipo de Distribuição: <b>{tipoDistribuicaoDesc}</b></Timeline.Item>

            {tipoDistribuicaoId === '5' && <Timeline.Item>
                <p>Equipes selecionadas: </p>
                {equipesDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {tipoDistribuicaoId === '6' && <Timeline.Item>
                <p>Usuários selecionados: </p>
                {usuariosDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {tipoDistribuicaoId === '7' && <Timeline.Item>
                <p>Tipos Usuários selecionados: </p>
                {tiposUsuarioDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            <Timeline.Item color="orange">Faz Redistribuição: <b>{!!fazRedistribuicao && !!tipoRedistribuicaoId ? 'Sim' : 'Não'}</b></Timeline.Item>

            {!!fazRedistribuicao && empresasRedistribuicaoDesc && empresasRedistribuicaoDesc.length > 0 && <Timeline.Item color="orange">
                <p>Empresas selecionadas (Redistribuição): </p>
                {empresasRedistribuicaoDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {!!fazRedistribuicao && !!tipoRedistribuicaoId && prazoRedistribuicao && <Timeline.Item color="orange">Prazo Redistribuição: <b>{prazoRedistribuicao} minutos</b></Timeline.Item>}

            {!!fazRedistribuicao && !!tipoRedistribuicaoId && qtdRedistribuicao && <Timeline.Item color="orange">Quantidade Redistribuição: <b>{qtdRedistribuicao} vezes</b></Timeline.Item>}

            {!!fazRedistribuicao && !!tipoRedistribuicaoId && <Timeline.Item color="orange">Tipo de Redistribuição: <b>{tipoRedistribuicaoDesc}</b></Timeline.Item>}

            {!!fazRedistribuicao && tipoRedistribuicaoId === '5' && <Timeline.Item color="orange">
                <p>Equipes selecionadas (Redistribuição): </p>
                {equipesRedistribuicaoDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {!!fazRedistribuicao && tipoRedistribuicaoId === '6' && <Timeline.Item color="orange">
                <p>Usuários selecionados (Redistribuição): </p>
                {usuariosRedistribuicaoDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            {!!fazRedistribuicao && tipoRedistribuicaoId === '7' && <Timeline.Item color="orange">
                <p>Tipos Usuários selecionados (Redistribuição): </p>
                {tiposUsuarioRedistribuicaoDesc?.map((e, index) => <Tag key={index}>{e}</Tag>)}
            </Timeline.Item>}

            <Timeline.Item color="red" dot={<SmileOutlined />}>
                <p>Fim</p>
            </Timeline.Item>
        </Timeline>
    )
};
export default ResumoDistribuicao;