import React, {useState, useEffect, Fragment} from 'react';
import {Card, CardBody} from 'reactstrap';
import {mask} from 'remask';

import Notify from '~/shared/Notify';

import CadastroGeral from '../components/CadastroGeral';
import Portais from '../components/Portais';
import Proprietarios from '../components/Proprietarios';
import Imagens from '../components/Imagens';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import CircularProgress from '~/components/HomeLoading';

import {Tabs} from '~/components/Tabs';
import {Tab} from '~/components/Tabs';

import useImovelProprietarios from '~/actions/ImovelProprietarios/useImovelProprietarios';
import useFinalidades from '~/actions/Finalidades/useFinalidades';
import useTipoImovel from '~/actions/TipoImovel/useTipoImovel';
import useLogradouroDropdown from '~/actions/DropDown/useLogradouroDropdown';
import useUnidadeMedida from '~/actions/DropDown/useUnidadeMedida';

import pattern from '~/infra/resources/pattern/mask';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
import getActionsMenus from '~/infra/resources/pattern/getActionsMenu';

import 'moment/locale/pt-br';
import moment from 'moment';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
moment.locale('pt-BR');

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  PORTAIS: 2,
  PROPRIETARIOS: 3,
  IMAGENS: 4,
};

const showTabs = {
  showTabPortal: false,
  showTabOwners: false,
  showTabImages: false,
};

function Imovel(props) {
  const roles = JSON.parse(localStorage.getItem('icaseRoles'));
  const isMaster =
    roles &&
    !!roles.find(
      (perfil) =>
        perfil.toUpperCase() === 'MASTER' ||
        perfil.toUpperCase() === 'DIRETOR' ||
        perfil.toUpperCase() === 'ADMMATRIZ',
    );
  const isMasterLead = JSON.parse(localStorage.getItem('icaseMasterLeads'));
  const isCorretor =
    roles && !!roles.find((perfil) => perfil.toUpperCase() === 'CORRETOR');

  const {actions} = getActionsMenus(props?.match?.params?.identificador);

  const isRoledToEdit = !!actions?.find(
    (action) => action.id === 'f97bf5a0-f8f4-401e-b5c0-3d9614fa927a',
  );

  showTabs.showTabPortal = !!actions?.find(
    (action) => action.id === '3bb68202-94f8-484c-b223-b8d714bee5cc',
  );

  showTabs.showTabOwners = !!actions?.find(
    (action) => action.id === '493dabac-0877-4683-ad0f-164f3cdf6a48',
  );

  showTabs.showTabImages = !!actions?.find(
    (action) => action.id === '38833ac6-a442-42a0-9376-ebfcded51da3',
  );

  const [loadPage, setloadPage] = useState(true);
  const [modo, setModo] = useState('');
  const [activeTab, setActiveTab] = useState(TABS.GERAL);
  const [disabledTabs, setDisabledTabs] = useState(true);
  const [initialValues, setInitialValues] = useState({});
  const [initialValuesImages, setInitialValuesImages] = useState({});
  const [defaultValueSituacoes, setDefaultValueSituacoes] = useState([]);
  const [imovelId, setImovelId] = useState(null);
  const [listDivulgacoes, setListDivulgacoes] = useState([]);
  const [listImages, setListImages] = useState([]);
  const [exibirAbaProprietarios, setExibirAbaProprietarios] = useState(true);
  const [identificador, setIdentificador] = useState();

  const [currentTipoImovel, setCurrentTipoImovel] = useState(1);
  const [pageSizeTipoImovel, setPageSizeTipoImovel] = useState(9999);
  const [nomeTipoImovel] = useState('');
  const [finalidadeId, setFinalidadeId] = useState();

  const [currentFinalidade, setCurrentFinalidade] = useState(1);
  const [pageSizeFinalidade, setPageSizeFinalidade] = useState(9999);
  const [nomeFinalidade, setNomeFinalidade] = useState('');

  const [logradouro, setLogradouro] = useState('');

  const {data: dataImovelProprietarios, onLoad: onLoadImovelProprietarios} =
    useImovelProprietarios();
  const {data: dataFinalidades} = useFinalidades(
    currentFinalidade,
    pageSizeFinalidade,
    nomeFinalidade,
  );
  const {data: dataTipoImovel, onLoad: onLoadTipoImovel} = useTipoImovel(
    currentTipoImovel,
    pageSizeTipoImovel,
    nomeTipoImovel,
    finalidadeId,
  );
  const {data: dataLogradouros} = useLogradouroDropdown(logradouro);

  const {data: dataCorretores, onLoad: onLoadCorretores} =
    useCorretorDropdown();
  const {data: dataUnidadeMedida} = useUnidadeMedida();

  async function generateInitialValueImages(images) {
    setListImages(images);

    const object = images.reduce(function (acc, image, index) {
      return Object.assign(acc, {
        [`${index}_id`]: image.id ? image.id : null,
        [`${index}_descricao`]: image.descricao,
        [`${index}_publicavel`]: image.id ? image.publicavel : true,
        [`${index}_destaque`]: image.id ? image.destaque : false,
        [`${index}_superDestaque`]: image.superDestaque,
        [`${index}_planta`]: image.planta,
        [`${index}_ordem`]: image.ordem,
      });
    }, {});

    return object;
  }

  async function generateInitialValue(dados) {
    let defaultBeneficios = [];
    let defaultSituacoes = [];
    let defaultPossuiInfra = [];

    await setOnLoadTipoImovel(dados?.finalidadeId);
    await setOnLoadLogradouro(dados?.logradouro?.nome);

    if (dados?.ocupado) defaultSituacoes.push('O');
    if (dados?.exclusividade) defaultSituacoes.push('E');
    if (dados?.autorizacao) defaultSituacoes.push('A');

    if (dados?.caracteristica?.viaPavimentada) {
      if (dados?.finalidade?.nome) {
        dados.finalidade.nome.toLowerCase() === 'rural'
          ? defaultPossuiInfra.push('V')
          : defaultBeneficios.push('VP');
      }
    }

    if (dados?.caracteristica?.agua) defaultPossuiInfra.push('A');
    if (dados?.caracteristica?.luz) defaultPossuiInfra.push('L');
    if (dados?.caracteristica?.piscina) defaultBeneficios.push('PI');
    if (dados?.caracteristica?.armarios) defaultBeneficios.push('AR');
    if (dados?.caracteristica?.despensas) defaultBeneficios.push('DE');
    if (dados?.caracteristica?.mobiliado) defaultBeneficios.push('MO');
    if (dados?.caracteristica?.salasDeJantar) defaultBeneficios.push('SJ');
    if (dados?.caracteristica?.closets) defaultBeneficios.push('CL');
    if (dados?.caracteristica?.escritorios) defaultBeneficios.push('ES');
    if (dados?.caracteristica?.lavabos) defaultBeneficios.push('LA');
    if (dados?.caracteristica?.dces) defaultBeneficios.push('DC');
    if (dados?.caracteristica?.banheirosServico) defaultBeneficios.push('BS');
    if (dados?.caracteristica?.varandas) defaultBeneficios.push('VA');
    if (dados?.caracteristica?.armarioCozinha) defaultBeneficios.push('AC');
    if (dados?.caracteristica?.churrasqueiras) defaultBeneficios.push('CH');
    if (dados?.caracteristica?.saloesFesta) defaultBeneficios.push('SF');
    if (dados?.caracteristica?.saloesDeGinastica) defaultBeneficios.push('SG');
    if (dados?.caracteristica?.quadraDeEsportes) defaultBeneficios.push('QE');
    if (dados?.caracteristica?.quintal) defaultBeneficios.push('QU');
    if (dados?.caracteristica?.copa) defaultBeneficios.push('CO');
    if (dados?.caracteristica?.redeEsgoto) defaultBeneficios.push('RE');
    if (dados?.caracteristica?.minhaCasaMinhaVida) defaultBeneficios.push('MV');

    setDefaultValueSituacoes(defaultSituacoes);

    setInitialValues({
      sincronizadoComDesktop: dados?.sincronizadoComDesktop || null,
      beneficios: defaultBeneficios,
      id: dados?.id || undefined,
      codImovel: dados?.codImovel || undefined,
      codigoExterno: dados?.codigoExterno || undefined,
      estado: dados?.logradouro?.bairro?.cidade?.estadoId || undefined,
      cidadeId: dados?.logradouro?.bairro?.cidadeId || undefined,
      cidade:
        String(dados?.logradouro?.bairro?.cidade?.nome.toUpperCase()) || null,
      bairro: dados?.logradouro?.bairro?.nome || null,
      logradouro: dados?.logradouro?.nome || null,
      logradouroId: dados?.logradouroId || undefined,
      tipoLogradouro: dados?.logradouro?.tipoLogradouro?.descricao || null,
      cep: dados.logradouro.cep
        ? mask(dados.logradouro.cep, [pattern.cep])
        : '',
      pais: 'BRASIL',
      situacao: String(dados?.situacao) || null,
      vencimentoAutorizacao: dados.vencimentoAutorizacao
        ? moment(dados.vencimentoAutorizacao.toString())
        : undefined,
      numeroChave: dados.numeroChave || undefined,
      chavesNaEmpresa: dados?.chavesNaEmpresa || null,
      situacaoFinanceira: dados?.situacaoFinanceira || null,
      situacaoDocumental: dados?.situacaoDocumental || null,
      aceitaPermuta: dados?.aceitaPermuta || null,
      ocupadoPor: dados?.ocupadoPor || null,
      andarTerreo: dados?.andarTerreo || null,
      padraoSocial: dados?.padraoSocial || null,
      operacaoImobiliaria: dados?.operacaoImobiliaria || null,
      tipoSoloId: dados?.tipoSoloId || undefined,
      tipoImovelId: dados.tipoImovelId || undefined,
      tipoImovel: dados.tipoImovel.descricao || null,
      finalidadeId: dados.finalidadeId || undefined,
      finalidade: dados?.finalidade?.nome || null,
      divulgavel: dados?.divulgavel || null,
      destaque: dados?.destaque || null,
      superDestaque: dados?.superDestaque || null,
      corretorCaptacaoId: dados?.corretorCaptacaoId || undefined,
      corretorCaptacao2Id: dados?.corretorCaptacao2Id || undefined,
      captacao: dados.captacao ? moment(dados.captacao.toString()) : moment(),
      dataCriacao: dados.dataCriacao
        ? moment(dados.dataCriacao.toString())
        : undefined,
      comissaoVenda: dados?.comissaoVenda || null,
      prestacaoValor: dados?.prestacaoValor || null,
      prestacoesFaltantes: dados?.prestacoesFaltantes || null,
      procedimentoVisita: dados?.procedimentoVisita || null,
      saldoDevedor: dados?.saldoDevedor || null,
      valorVenda: dados?.valorVenda || null,
      valorAgio: dados?.valorAgio || null,
      valorAluguel: dados?.valorAluguel || null,
      tituloAmigavel: dados?.tituloAmigavel || null,
      url: dados?.url || null,
      url360: dados?.url360 || null,
      condominioIsento: dados?.condominioIsento || false,
      valorCondominio: dados?.valorCondominio || null,
      iptu: dados?.iptu || null,
      unidadeConsumidoraEnergia: dados?.unidadeConsumidoraEnergia || null,
      unidadeConsumidoraAgua: dados?.unidadeConsumidoraAgua || null,
      condicoesPgtoIptu: dados.condicoesPgtoIptu
        ? String(dados.condicoesPgtoIptu)
        : null,
      valorIptu: dados?.valorIptu || null,
      valorSeguroFianca: dados?.valorSeguroFianca || null,
      valorSeguroIncendio: dados?.valorSeguroIncendio || null,
      condicoesPagamento: dados?.condicoesPagamento || null,
      loteApto: dados?.endereco?.loteApto || null,
      complemento: dados?.endereco?.complemento || null,
      bairroComercialId: dados?.endereco?.bairroComercialId || undefined,
      bairroComercialNome: dados?.endereco?.bairroComercial?.nome || undefined,
      latitude: dados?.endereco?.location?.coordinate?.x || null,
      longitude: dados?.endereco?.location?.coordinate?.y || null,
      condominioId: dados?.endereco?.condominioId || undefined,
      numero: dados?.endereco?.numero || null,
      quadraBloco: dados?.endereco?.quadraBloco || null,

      benfeitorias: dados?.area?.benfeitorias || null,
      dadosCampo: dados?.area?.dadosCampo || null,
      distanciaAsfalto: dados?.area?.distanciaAsfalto || null,
      distanciaCapital: dados?.area?.distanciaCapital || null,
      distanciaSede: dados?.area?.distanciaSede || null,

      caracteristicas: dados?.caracteristica?.caracteristicas || null,
      qtdAndares: dados?.caracteristica?.qtdAndares || null,
      nroAndar: dados?.caracteristica?.nroAndar || null,
      possuiElevador: dados?.caracteristica?.possuiElevador || null,
      apartamentosPorAndar: dados?.caracteristica?.apartamentosPorAndar || null,
      quantidadeTorres: dados?.caracteristica?.quantidadeTorres || null,
      anoConstrucao: dados?.caracteristica?.anoConstrucao || null,
      porDoSol: dados?.caracteristica?.porDoSol || null,
      vagasGaragem: dados?.caracteristica?.vagasGaragem || null,
      areaConstruida: dados?.caracteristica?.areaConstruida || null,
      areaConstruidaUnidadeMedidaId:
        dados?.caracteristica?.areaPrivadaUniMedidaId || null,
      areaTerreno: dados?.caracteristica?.areaTerreno || null,
      areaTerrenoUnidadeMedidaId:
        dados?.caracteristica?.areaTotalUniMedidaId || null,
      quantidadeQuartos: dados?.caracteristica?.quantidadeQuartos || null,
      sendoSuite: dados?.caracteristica?.sendoSuite || null,
      quantidadeSuites: dados?.caracteristica?.quantidadeSuites || null,
      quantidadeBanheiros: dados?.caracteristica?.quantidadeBanheiros || null,
      vagasGaragemDescobertas:
        dados?.caracteristica?.vagasGaragemDescobertas || null,
      totalGaragens:
        Number(
          dados?.caracteristica?.vagasGaragem
            ? dados?.caracteristica?.vagasGaragem
            : 0,
        ) +
        Number(
          dados?.caracteristica?.vagasGaragemDescobertas
            ? dados?.caracteristica?.vagasGaragemDescobertas
            : 0,
        ),
      negocioIndicado: dados?.caracteristica?.negocioIndicado || null,
      proximaAtualizacao: dados?.caracteristica?.proximaAtualizacao
        ? moment(dados.caracteristica.proximaAtualizacao.toString())
        : moment().add(30, 'days'),
      medidasFrente: dados?.caracteristica?.frente || null,
      medidasFundos: dados?.caracteristica?.fundo || null,
      medidasLadoDireito: dados?.caracteristica?.ladoDireito || null,
      medidasLadoEsquerdo: dados?.caracteristica?.ladoEsquerdo || null,
      medidasChanfro: dados?.caracteristica?.chanfro || null,
      muroFrente: dados?.caracteristica?.muroFrente || null,
      muroFundo: dados?.caracteristica?.muroFundo || null,
      muroLados: dados?.caracteristica?.muroLados || null,
      muroLadoDireito: dados?.caracteristica?.roLadoDireito || null,
      alqueires: dados?.area?.alqueires || null,
      hectares: dados?.area?.hectares || null,
      aberta: dados?.area?.aberta || null,
      preservacao: dados?.area?.preservacao || null,
      aFormar: dados?.area?.aFormar || null,
      possuiInfra: defaultPossuiInfra,
    });
  }

  async function setOnLoadTipoImovel(finalidadeId) {
    setCurrentTipoImovel(1);
    setPageSizeTipoImovel(9999);
    setFinalidadeId(finalidadeId);
  }

  async function setOnLoadFinalidade(nome) {
    setCurrentFinalidade(1);
    setPageSizeFinalidade(9999);
    setNomeFinalidade(nome);
  }

  async function setOnLoadLogradouro(logradouro) {
    setLogradouro(logradouro);
  }

  function keepFilter() {
    localStorage.setItem('latitudeKeepFilterImovel', true);
  }

  useEffect(() => {
    async function fetch() {
      if (props.match.params) {
        const identificador = props.match.params?.identificador;
        const id = props.match.params?.id;

        if (props.match.params.modo === MODO.EDITAR) {
          setIdentificador(identificador);
          setModo(MODO.EDITAR);
          setDisabledTabs(false);
          setImovelId(id);

          await api
            .get(url.IMOVEL.CONSULTA(id))
            .then(async (res) => {
              const data = res.data;

              setListDivulgacoes(data.divulgacoes);

              const exibeProprietariosAba =
                isMaster ||
                isRoledToEdit ||
                isMasterLead ||
                (isCorretor &&
                  data?.corretorCaptacaoId ===
                    localStorage.getItem('icaseUsuarioId'));

              setExibirAbaProprietarios(exibeProprietariosAba);

              if (exibeProprietariosAba && showTabs.showTabOwners)
                await onLoadImovelProprietarios(data.id);

              const object = await generateInitialValueImages(data.imagens);
              setInitialValuesImages(object);
              await generateInitialValue(data);

              setTimeout(() => {
                setloadPage(false);
              }, 2000);
            })
            .catch((err) => {
              Notify(
                'error',
                pt.comum.atencao,
                'Erro ao carregar os dados do imóvel!',
              );
            });
        } else {
          setIdentificador(identificador);
          setModo(MODO.CADASTRAR);
          setloadPage(false);
          setInitialValues({situacao: '1'});
        }
      }
    }

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function handlePercentualProprietarios() {
    if (!exibirAbaProprietarios) {
      return true;
    }

    const retorno = dataImovelProprietarios.reduce((totalPorcentagem, item) => {
      return (totalPorcentagem += item.porcentagem);
    }, 0);

    if (retorno < 100) {
      Notify(
        'error',
        pt.comum.atencao,
        'Para prosseguir é necessário informar o total de 100% para os proprietários!',
      );
      return false;
    } else {
      return true;
    }
  }

  return (
    <div className="px-4 pb-4">
      {loadPage && <CircularProgress />}
      <div>{loadPage && <SkeletonTableBasic />}</div>

      {!loadPage && (
        <Fragment>
          <Card className="card-custom-basic card-transparent">
            <CardBody className="p-0">
              <Tabs
                activeTab={activeTab}
                setTab={(tab) => setActiveTab(tab)}
                disabledTabs={disabledTabs}
                typeValidation="I"
                handleEventValidation={handlePercentualProprietarios}>
                <Tab title="Cadastro geral" desc="">
                  <Card className="card-custom card-shadowless rounded-top-0 py-0">
                    <CardBody>
                      <CadastroGeral
                        dataFinalidades={dataFinalidades}
                        setOnLoadFinalidade={setOnLoadFinalidade}
                        dataTipoImovel={dataTipoImovel}
                        onLoadTipoImovel={onLoadTipoImovel}
                        setOnLoadTipoImovel={setOnLoadTipoImovel}
                        dataLogradouros={dataLogradouros}
                        setOnLoadLogradouro={setOnLoadLogradouro}
                        onLoadCorretores={onLoadCorretores}
                        dataCorretores={dataCorretores}
                        defaultValueSituacoes={defaultValueSituacoes}
                        generateInitialValue={(value) =>
                          generateInitialValue(value)
                        }
                        initialValues={initialValues}
                        setActiveTab={(value) => setActiveTab(value)}
                        setImovelId={(value) => setImovelId(value)}
                        setDisabledTabs={(value) => setDisabledTabs(value)}
                        modo={modo}
                        identificador={identificador}
                        dataDivulgacoes={listDivulgacoes}
                        dataUnidadeMedida={dataUnidadeMedida}
                        keepFilter={keepFilter}
                        showTabs={showTabs}
                      />
                    </CardBody>
                  </Card>
                </Tab>

                {showTabs.showTabPortal && (
                  <Tab title="Portais" desc="">
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                      <CardBody>
                        <Portais
                          imovelId={imovelId}
                          identificador={identificador}
                          listDivulgacoes={listDivulgacoes}
                          modo={modo}
                          keepFilter={keepFilter}
                          setActiveTab={(value) => setActiveTab(value)}
                          showTabs={showTabs}
                        />
                      </CardBody>
                    </Card>
                  </Tab>
                )}

                {exibirAbaProprietarios && showTabs.showTabOwners && (
                  <Tab title="Proprietário(s)" desc="">
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                      <CardBody>
                        <Proprietarios
                          imovelId={imovelId}
                          modo={MODO.CADASTRAR}
                          dataImovelProprietarios={dataImovelProprietarios}
                          onLoadImovelProprietarios={onLoadImovelProprietarios}
                          identificador={identificador}
                          keepFilter={keepFilter}
                          setActiveTab={(value) => setActiveTab(value)}
                          showTabs={showTabs}
                        />
                      </CardBody>
                    </Card>
                  </Tab>
                )}

                {showTabs.showTabImages && (
                  <Tab title="Imagens" desc="">
                    <Card className="card-custom card-shadowless rounded-top-0 mb-0">
                      <CardBody>
                        <Imagens
                          modo={modo}
                          imovelId={imovelId}
                          listImages={listImages}
                          initialValuesImages={initialValuesImages}
                          generateInitialValueImages={(value) =>
                            generateInitialValueImages(value)
                          }
                          setInitialValuesImages={(value) =>
                            setInitialValuesImages(value)
                          }
                          identificador={identificador}
                          keepFilter={keepFilter}
                        />
                      </CardBody>
                    </Card>
                  </Tab>
                )}
              </Tabs>
            </CardBody>
          </Card>
        </Fragment>
      )}
    </div>
  );
}

export default Imovel;
