import React, { useState, useEffect, Fragment } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Divider,
  Switch,
  Checkbox,
  Tooltip,
} from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Row, Col, CardHeader } from 'reactstrap';
import { Icon, Image } from 'semantic-ui-react';
import { mask, unMask } from 'remask';
import chatgpt_icon from '~/assets/img/chatgpt-icon.svg';

import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import ModalConfirm from '~/components/ModalConfirm';
import Button from '~/shared/Button';
import Notify from '~/shared/Notify';

import enumSituacaoImovel from '~/infra/resources/strings/enums/situacaoImovel';
import enumSituacaoFinanceiraImovel from '~/infra/resources/strings/enums/situacaoFinanceiraImovel';
import enumSituacaoDocumentalImovel from '~/infra/resources/strings/enums/situacaoDocumentalImovel';
import enumPadraoSocialImovel from '~/infra/resources/strings/enums/padraoSocialImovel';
import enumOperacaoImobiliaria from '~/infra/resources/strings/enums/operacaoImobiliaria';
import enumBenfeitoriasImovel from '~/infra/resources/strings/enums/benfeitoriasImovel';
import enumCondicoesPgtoIPTU from '~/infra/resources/strings/enums/condicoesPgtoIPTU';

import ModalCondominio from '~/components/ModalCondominio';
import ModalFinalidade from '~/components/ModalFinalidade';
import ModalTipoImovel from '~/components/ModalTipoImovel';
import ModalLogradouro from '~/components/ModalLogradouro';

import url from '~/infra/urls';
import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';
import pattern from '~/infra/resources/pattern/mask';
import formatedReal from '~/shared/formatedReal';

import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import useBairroDropdownWithoutInit from '~/actions/DropDown/useBairroDropdownWithoutInit';
import useRoles from '~/hooks/use-roles';
import useImovelValidate from './components/useImovelValidate';
import useCondominioDropdown from '~/actions/DropDown/useCondomionio';
import { GoogleMaps } from '~/components/GoogleMaps';
moment.locale('pt-BR');

const dateFormat = 'DD/MM/YYYY';
const { Option } = Select;
const { TextArea } = Input;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

const TABS = {
  GERAL: 1,
  PORTAIS: 2,
  PROPRIETARIOS: 3,
  IMAGENS: 4,
};

const situacoes = [
  { label: 'Autorização', value: 'A' },
  { label: 'Exclusivo', value: 'E' },
  { label: 'Ocupado', value: 'O' },
];

const possuiInfra = [
  { label: 'Via pavimentada', value: 'V' },
  { label: 'Luz', value: 'L' },
  { label: 'Água', value: 'A' },
];

const ColCt = ({ hide, children, ...rest }) => {
  if (hide === true) return null;

  return <Col {...rest}>{children}</Col>;
};

const FormItemCt = ({ isRequired, children, ...rest }) => {
  if (isRequired === true)
    return (
      <Form.Item
        rules={[
          {
            required: true,
            message: 'Favor informar',
          },
        ]}
        {...rest}>
        {children}
      </Form.Item>
    );

  return <Form.Item {...rest}>{children}</Form.Item>;
};

function CadastroGeral({
  dataFinalidades,
  setOnLoadFinalidade,
  dataTipoImovel,
  onLoadTipoImovel,
  setOnLoadTipoImovel,
  dataLogradouros,
  setOnLoadLogradouro,
  dataCorretores,
  onLoadCorretores,
  defaultValueSituacoes,
  generateInitialValue,
  initialValues,
  setActiveTab,
  setImovelId: setImovelIdAba,
  setDisabledTabs,
  modo,
  identificador,
  dataDivulgacoes,
  dataUnidadeMedida,
  keepFilter,
  showTabs,
}) {
  const [visibleForm] = useState(true);
  const [loading, setIsLoading] = useState(false);
  const [condition, setCondition] = useState('');
  const [finalidade, setFinalidade] = useState('');
  const [operacaoImobiliaria, setOperacaoImobiliaria] = useState(null);
  const [situacaoFinanceira, setSituacaoFinanceira] = useState(null);

  const [checkedAutorizacao, setCheckedAutorizacao] = useState(false);
  const [imovelId, setImovelId] = useState(0);

  const [loadingEndereco, setLoadingEndereco] = useState(false);
  // const [visible, setVisible] = useState(false);
  const [sincronizadoComDesktop, setSincronizadoComDesktop] = useState(true);
  const [showExternalCode, setShowExternalCode] = useState(true);
  const [loadingSync, setLoadingSync] = useState(false);
  const [imovelOcupado, setImovelOcupado] = useState(false);
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [modalFinalidadeOpen, setModalFinalidadeOpen] = useState(false);
  const [modalTipoImovelOpen, setModalTipoImovelOpen] = useState(false);
  const [modalCondominioOpen, setModalCondominioOpen] = useState(false);
  const [modalLogradouroOpen, setModalLogradouroOpen] = useState(false);
  const [selectedFinalidadeId, setSelectedFinalidadeId] = useState(undefined);
  const [selectedTipoImovelId, setSelectedTipoImovelId] = useState(undefined);
  const [disabledTipoImovel, setDisabledTipoImovel] = useState(true);
  const { data: dataBairros, onLoad: onLoadBairro } =
    useBairroDropdownWithoutInit();

  const [selectedLogradouroId, setSelectedLogradouroId] = useState();
  const [execUseEffect, setExecUseEffect] = useState();
  const [loadGPT, setLoadGPT] = useState(false);
  const [latLng, setLatLng] = useState({ latitude: 0, longitude: 0, suggestionPlace: '' });
  const [form] = Form.useForm();
  const { fieldIsRequired, fieldIsHidden } = useImovelValidate(
    selectedTipoImovelId,
    selectedFinalidadeId,
  );

  const {
    data: condominios,
    onLoad: onLoadCond,
    cancelRequest: cancelRequestCond,
  } = useCondominioDropdown();

  const roles = useRoles();

  async function fetchTipoImovel(finalidadeId, isClear) {
    setDisabledTipoImovel(true);
    setOnLoadTipoImovel(finalidadeId);

    if (isClear) {
      form.setFieldsValue({ tipoImovelId: null });
      setDisabledTipoImovel(false);
    }
  }

  async function onSearchCondominio(value) {
    cancelRequestCond();
    await onLoadCond({ nome: value, tipo: null });
  }


  function onSetLatitudeLongitude(latitude, longitude, suggestionPlace) {
    console.log('Latitude: ', latitude, 'Longitude: ', longitude);
    setLatLng({ latitude, longitude, suggestionPlace });
    form.setFieldValue('latitude', latitude);
    form.setFieldValue('longitude', longitude);
    console.log('Form: ', form.getFieldsValue(['latitude', 'longitude']));
  }


  useEffect(() => {
    setOnLoadLogradouro(initialValues.logradouro);

    if (modo === MODO.EDITAR) {
      if (initialValues) setImovelId(initialValues?.id);

      if (initialValues?.finalidade)
        setFinalidade(initialValues.finalidade.toLowerCase());
      if (initialValues?.tipoImovel)
        setCondition(initialValues.tipoImovel.toLowerCase());
      if (initialValues?.operacaoImobiliaria)
        setOperacaoImobiliaria(initialValues.operacaoImobiliaria);
      if (initialValues?.situacaoFinanceira)
        setSituacaoFinanceira(initialValues.situacaoFinanceira);
      setShowExternalCode(initialValues.codigoExterno);
      if (initialValues?.sincronizadoComDesktop)
        setSincronizadoComDesktop(initialValues.sincronizadoComDesktop);
      setImovelOcupado(
        defaultValueSituacoes?.find((f) => f === 'O') ? true : false,
      );

      form.setFieldsValue({
        prestacaoValor: initialValues.prestacaoValor
          ? formatedReal(initialValues.prestacaoValor.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        saldoDevedor: initialValues.saldoDevedor
          ? formatedReal(initialValues.saldoDevedor.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        comissaoVenda: initialValues.comissaoVenda
          ? formatedReal(initialValues.comissaoVenda.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorIptu: initialValues.valorIptu
          ? formatedReal(initialValues.valorIptu.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorSeguroFianca: initialValues.valorSeguroFianca
          ? formatedReal(initialValues.valorSeguroFianca.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorSeguroIncendio: initialValues.valorSeguroIncendio
          ? formatedReal(
            initialValues.valorSeguroIncendio.toFixed(2).toString(),
          )
            .replace('R$', '')
            .trim()
          : undefined,
        valorVenda: initialValues.valorVenda
          ? formatedReal(initialValues.valorVenda.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorAgio: initialValues.valorAgio
          ? formatedReal(initialValues.valorAgio.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorAluguel: initialValues.valorAluguel
          ? formatedReal(initialValues.valorAluguel.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        valorCondominio: initialValues.valorCondominio
          ? formatedReal(initialValues.valorCondominio.toFixed(2).toString())
            .replace('R$', '')
            .trim()
          : undefined,
        latitude: initialValues?.imovelEndereco?.latitude,
        longitude: initialValues?.imovelEndereco?.longitude,
      });

      setDisabledTipoImovel(false);

      onLoadCond({ condominioId: initialValues?.condominioId });

      onLoadBairro({ cidadeId: null, nome: initialValues?.bairroComercialNome });

      onSetLatitudeLongitude(
        initialValues?.latitude || 0, 
        initialValues?.longitude || 0,
        `${initialValues?.bairro}, ${initialValues?.cidade} - ${initialValues?.estado}`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialValues]);

  useEffect(() => {
    setLoadingEndereco(true);

    if (dataLogradouros.length > 0 && selectedLogradouroId) {
      const dataEndereco = dataLogradouros.find(
        (item) => item.id === selectedLogradouroId,
      );

      form.setFieldsValue({
        cep: dataEndereco?.cep
          ? mask(dataEndereco?.cep, [pattern.cep])
          : dataEndereco?.cep,
        tipoLogradouro: dataEndereco?.tipoLogradouro?.descricao,
        bairro: dataEndereco?.bairro?.nome,
        cidade: dataEndereco?.bairro?.cidade?.nome,
        cidadeId: dataEndereco?.bairro?.cidadeId,
        estado: dataEndereco?.bairro?.cidade?.estadoId,
        pais: dataEndereco ? 'BRASIL' : '',
      });
    }

    setTimeout(() => {
      setLoadingEndereco(false);
    }, 3000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLogradouros, execUseEffect]);

  async function handleFinalidade(finalidadeId) {
    setSelectedFinalidadeId(finalidadeId);
    const data = dataFinalidades.find((item) => item.id === finalidadeId);
    setFinalidade(data.nome.toLowerCase());
    setCondition('');

    if (modo === MODO.CADASTRAR) {
      //form.resetFields();
      form.setFieldsValue({ finalidadeId });
    }

    fetchTipoImovel(finalidadeId, true);

    setTimeout(() => form.validateFields(), 1000);
  }

  function handleChangeSituacoes(value) {
    setImovelOcupado(value?.find((f) => f === 'O') ? true : false);
    setCheckedAutorizacao(handleSelectedCheck(value, 'A'));
  }

  function handleConvertNumber(value) {
    return isNaN(value) && value === '' ? undefined : value;
  }

  function handleSelectedCheck(listChecked, value) {
    return listChecked && listChecked?.length > 0
      ? listChecked.some((item) => item === value)
      : false;
  }

  function handleAbas(showTabs) {
    if (showTabs.showTabPortal) setActiveTab(TABS.PORTAIS);
    else if (showTabs.showTabOwners) setActiveTab(TABS.PROPRIETARIOS);
    else if (showTabs.showTabImages) setActiveTab(TABS.IMAGENS);

    setOpenModalConfirm(false);
  }

  async function onSaveCadastroGeral() {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (dados) => {
        if (checkedAutorizacao && !dados.vencimentoAutorizacao) {
          setIsLoading(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Favor informar o vencimento da autorização',
          );
        } else {
          let nomeCondominio = '';
          const item = condominios.find(
            (item) => item.id === dados.condominioId,
          );

          if (item !== undefined) {
            nomeCondominio = item?.nome;
          }

          const nomeBairro = dados.bairro !== undefined ? dados.bairro : '';

          let tituloAmigavel = '';
          if (nomeCondominio) tituloAmigavel = `Cond.: ${nomeCondominio} `;

          tituloAmigavel += nomeBairro !== '' ? `Bairro: ${nomeBairro} ` : '';

          if (
            (operacaoImobiliaria === 2 || operacaoImobiliaria === 3) &&
            dados.valorVenda &&
            tituloAmigavel !== ''
          )
            tituloAmigavel += `Valor: R$ ${dados.valorVenda}`;

          if (modo === MODO.CADASTRAR) {
            const payload = {
              logradouroId: dados.logradouroId,
              ocupado: handleSelectedCheck(dados.situacoes, 'O'),
              situacao: dados.situacao,
              vencimentoAutorizacao: dados.vencimentoAutorizacao,
              numeroChave: dados.numeroChave || undefined,
              chavesNaEmpresa: dados.chavesNaEmpresa,
              situacaoFinanceira: dados.situacaoFinanceira,
              situacaoDocumental: dados.situacaoDocumental,
              aceitaPermuta: dados.aceitaPermuta,
              andarTerreo: dados.andarTerreo,
              ocupadoPor: dados.ocupadoPor,
              padraoSocial: dados.padraoSocial,
              operacaoImobiliaria: dados.operacaoImobiliaria,
              tipoSoloId: dados.tipoSoloId,
              tipoImovelId: dados.tipoImovelId,
              finalidadeId: dados.finalidadeId,
              destaque: dados.destaque,
              superDestaque: dados.superDestaque,
              corretorCaptacaoId: dados.corretorCaptacaoId,
              corretorCaptacao2Id: dados.corretorCaptacao2Id,
              captacao: dados.captacao,
              exclusividade: handleSelectedCheck(dados.situacoes, 'E'),
              comissaoVenda: dados.comissaoVenda
                ? Number(
                  dados.comissaoVenda.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              prestacaoValor: dados.prestacaoValor
                ? Number(
                  dados.prestacaoValor.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              prestacoesFaltantes: handleConvertNumber(
                dados.prestacoesFaltantes,
              ),
              procedimentoVisita: dados.procedimentoVisita,
              autorizacao: handleSelectedCheck(dados.situacoes, 'A'),
              saldoDevedor: dados.saldoDevedor
                ? Number(
                  dados.saldoDevedor.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorVenda: dados.valorVenda
                ? Number(
                  dados.valorVenda.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorAgio: dados.valorAgio
                ? Number(
                  dados.valorAgio.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorAluguel: dados.valorAluguel
                ? Number(
                  dados.valorAluguel.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              tituloAmigavel: dados.tituloAmigavel
                ? dados.tituloAmigavel
                : tituloAmigavel.trim(),
              url: dados.url,
              url360: dados.url360,
              condominioIsento: dados?.condominioIsento,
              valorCondominio: dados.valorCondominio
                ? Number(
                  dados.valorCondominio.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              iptu: dados?.iptu,
              unidadeConsumidoraEnergia: dados?.unidadeConsumidoraEnergia,
              unidadeConsumidoraAgua: dados?.unidadeConsumidoraAgua,
              condicoesPgtoIptu: dados?.condicoesPgtoIptu,
              valorIptu: dados?.valorIptu
                ? Number(
                  dados?.valorIptu.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorSeguroFianca: dados?.valorSeguroFianca
                ? Number(
                  dados?.valorSeguroFianca
                    .replaceAll('.', '')
                    .replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorSeguroIncendio: dados?.valorSeguroIncendio
                ? Number(
                  dados.valorSeguroIncendio
                    .replaceAll('.', '')
                    .replace(',', '.'),
                ).toFixed(2)
                : undefined,
              condicoesPagamento: dados.condicoesPagamento,
              imovelEndereco: {
                loteApto: dados.loteApto,
                complemento: dados.complemento,
                bairroComercialId: dados?.bairroComercialId,
                condominioId: dados.condominioId,
                numero: dados.numero,
                quadraBloco: dados.quadraBloco,
                latitude: latLng.latitude,
                longitude: latLng.longitude,
              },
              imovelArea: {
                aberta: dados.aberta,
                aFormar: dados.aFormar,
                benfeitorias: dados.benfeitorias,
                dadosCampo: dados.dadosCampo,
                distanciaAsfalto: handleConvertNumber(dados.distanciaAsfalto),
                distanciaCapital: handleConvertNumber(dados.distanciaCapital),
                distanciaSede: handleConvertNumber(dados.distanciaSede),
                preservacao: dados.preservacao,
                alqueires: handleConvertNumber(dados.alqueires),
                hectares: handleConvertNumber(dados.hectares),
              },
              imovelCaracteristica: {
                caracteristicas: dados.caracteristicas,
                viaPavimentada:
                  condition && finalidade && finalidade.includes('rural')
                    ? handleSelectedCheck(dados.possuiInfra, 'V')
                    : handleSelectedCheck(dados.beneficios, 'VP'),
                qtdAndares: handleConvertNumber(dados.qtdAndares),
                nroAndar: handleConvertNumber(dados.nroAndar),
                possuiElevador: handleConvertNumber(dados.possuiElevador),
                apartamentosPorAndar: handleConvertNumber(
                  dados.apartamentosPorAndar,
                ),
                quantidadeTorres: handleConvertNumber(dados.quantidadeTorres),
                anoConstrucao: handleConvertNumber(dados.anoConstrucao),
                porDoSol: dados.porDoSol,
                vagasGaragem: handleConvertNumber(dados.vagasGaragem),
                muroFrente: dados.muroFrente,
                muroFundo: dados.muroFundo,
                muroLados: dados.muroLados,
                areaConstruida: handleConvertNumber(dados.areaConstruida),
                areaTotalUniMedidaId: dados.areaTerrenoUnidadeMedidaId,
                areaTerreno: handleConvertNumber(dados.areaTerreno),
                areaPrivadaUniMedidaId: dados.areaConstruidaUnidadeMedidaId,
                quantidadeQuartos: handleConvertNumber(dados.quantidadeQuartos),
                sendoSuite: dados.sendoSuite,
                quantidadeSuites: handleConvertNumber(dados.quantidadeSuites),
                quantidadeBanheiros: handleConvertNumber(
                  dados.quantidadeBanheiros,
                ),
                piscina: handleSelectedCheck(dados.beneficios, 'PI'),
                agua: handleSelectedCheck(dados.possuiInfra, 'A'),
                luz: handleSelectedCheck(dados.possuiInfra, 'L'),
                // tipoConstrucao: dados.tipoConstrucao,
                vagasGaragemDescobertas: handleConvertNumber(
                  dados.vagasGaragemDescobertas,
                ),
                armarios: handleSelectedCheck(dados.beneficios, 'AR'),
                despensas: handleSelectedCheck(dados.beneficios, 'DE'),
                mobiliado: handleSelectedCheck(dados.beneficios, 'MO'),
                salasDeJantar: handleSelectedCheck(dados.beneficios, 'SJ'),
                closets: handleSelectedCheck(dados.beneficios, 'CL'),
                escritorios: handleSelectedCheck(dados.beneficios, 'ES'),
                lavabos: handleSelectedCheck(dados.beneficios, 'LA'),
                dces: handleSelectedCheck(dados.beneficios, 'DC'),
                banheirosServico: handleSelectedCheck(dados.beneficios, 'BS'),
                varandas: handleSelectedCheck(dados.beneficios, 'VA'),
                armarioCozinha: handleSelectedCheck(dados.beneficios, 'AC'),
                churrasqueiras: handleSelectedCheck(dados.beneficios, 'CH'),
                saloesFesta: handleSelectedCheck(dados.beneficios, 'SF'),
                saloesDeGinastica: handleSelectedCheck(dados.beneficios, 'SG'),
                quadraDeEsportes: handleSelectedCheck(dados.beneficios, 'QE'),
                quintal: handleSelectedCheck(dados.beneficios, 'QU'),
                copa: handleSelectedCheck(dados.beneficios, 'CO'),
                redeEsgoto: handleSelectedCheck(dados.beneficios, 'RE'),
                minhaCasaMinhaVida: handleSelectedCheck(dados.beneficios, 'MV'),
                negocioIndicado: dados.negocioIndicado,
                proximaAtualizacao: dados.proximaAtualizacao,
                frente: Number(dados.medidasFrente || 0),
                fundo: Number(dados.medidasFundos || 0),
                ladoDireito: Number(dados.medidasLadoDireito || 0),
                ladoEsquerdo: Number(dados.medidasLadoEsquerdo || 0),
                chanfro: Number(dados.medidasChanfro || 0),
                muroLadoDireito: dados.muroLadoDireito,
              },
              divulgacaoImovel: dataDivulgacoes,
            };

            await api
              .post(url.IMOVEL.CADASTRAR(), payload)
              .then((res) => {
                setImovelIdAba(res.data.data.id);
                setDisabledTabs(false);
                setIsLoading(false);
                generateInitialValue(res.data.data);
                setOpenModalConfirm(true);
              })
              .catch((err) => {
                setIsLoading(false);
                const { error } = err.response.data;
                if (error) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                }
              });
          } else if (modo === MODO.EDITAR) {
            const payload = {
              imovelId,
              logradouroId: dados.logradouroId,
              ocupado: handleSelectedCheck(dados.situacoes, 'O'),
              situacao: dados.situacao,
              vencimentoAutorizacao: dados.vencimentoAutorizacao,
              numeroChave: dados.numeroChave || undefined,
              chavesNaEmpresa: dados.chavesNaEmpresa,
              situacaoFinanceira: dados.situacaoFinanceira,
              situacaoDocumental: dados.situacaoDocumental,
              aceitaPermuta: dados.aceitaPermuta,
              andarTerreo: dados.andarTerreo,
              ocupadoPor: dados.ocupadoPor,
              padraoSocial: dados.padraoSocial,
              operacaoImobiliaria: dados.operacaoImobiliaria,
              tipoSoloId: dados.tipoSoloId,
              tipoImovelId: dados.tipoImovelId,
              finalidadeId: dados.finalidadeId,
              destaque: dados.destaque,
              superDestaque: dados.superDestaque,
              corretorCaptacaoId: dados.corretorCaptacaoId,
              corretorCaptacao2Id: dados.corretorCaptacao2Id,
              captacao: dados.captacao,
              exclusividade: handleSelectedCheck(dados.situacoes, 'E'),
              comissaoVenda: dados.comissaoVenda
                ? Number(
                  dados.comissaoVenda.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              prestacaoValor: dados.prestacaoValor
                ? Number(
                  dados.prestacaoValor.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              prestacoesFaltantes: handleConvertNumber(
                dados.prestacoesFaltantes,
              ),
              procedimentoVisita: dados.procedimentoVisita,
              autorizacao: handleSelectedCheck(dados.situacoes, 'A'),
              saldoDevedor: dados.saldoDevedor
                ? Number(
                  dados.saldoDevedor.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorVenda: dados.valorVenda
                ? Number(
                  dados.valorVenda.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorAgio: dados.valorAgio
                ? Number(
                  dados.valorAgio.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorAluguel: dados.valorAluguel
                ? Number(
                  dados.valorAluguel.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              tituloAmigavel: dados.tituloAmigavel
                ? dados.tituloAmigavel
                : tituloAmigavel.trim(),
              url: dados.url,
              url360: dados.url360,
              condominioIsento: dados?.condominioIsento,
              valorCondominio: dados.valorCondominio
                ? Number(
                  dados.valorCondominio.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              iptu: dados.iptu,
              unidadeConsumidoraEnergia: dados.unidadeConsumidoraEnergia,
              unidadeConsumidoraAgua: dados.unidadeConsumidoraAgua,
              condicoesPgtoIptu: dados.condicoesPgtoIptu,
              valorIptu: dados.valorIptu
                ? Number(
                  dados.valorIptu.replaceAll('.', '').replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorSeguroFianca: dados.valorSeguroFianca
                ? Number(
                  dados.valorSeguroFianca
                    .replaceAll('.', '')
                    .replace(',', '.'),
                ).toFixed(2)
                : undefined,
              valorSeguroIncendio: dados.valorSeguroIncendio
                ? Number(
                  dados.valorSeguroIncendio
                    .replaceAll('.', '')
                    .replace(',', '.'),
                ).toFixed(2)
                : undefined,
              condicoesPagamento: dados.condicoesPagamento,
              imovelEndereco: {
                loteApto: dados.loteApto,
                complemento: dados.complemento,
                bairroComercialId: dados?.bairroComercialId,
                condominioId: dados.condominioId,
                numero: dados.numero,
                quadraBloco: dados.quadraBloco,
                latitude: dados.latitude,
                longitude: dados.longitude,
              },
              imovelArea: {
                aberta: dados.aberta,
                aFormar: dados.aFormar,
                benfeitorias: dados.benfeitorias,
                dadosCampo: dados.dadosCampo,
                distanciaAsfalto: handleConvertNumber(dados.distanciaAsfalto),
                distanciaCapital: handleConvertNumber(dados.distanciaCapital),
                distanciaSede: handleConvertNumber(dados.distanciaSede),
                preservacao: dados.preservacao,
                alqueires: handleConvertNumber(dados.alqueires),
                hectares: handleConvertNumber(dados.hectares),
              },
              imovelCaracteristica: {
                caracteristicas: dados.caracteristicas,
                viaPavimentada:
                  condition && finalidade && finalidade.includes('rural')
                    ? handleSelectedCheck(dados.possuiInfra, 'V')
                    : handleSelectedCheck(dados.beneficios, 'VP'),
                qtdAndares: handleConvertNumber(dados.qtdAndares),
                nroAndar: handleConvertNumber(dados.nroAndar),
                possuiElevador: handleConvertNumber(dados.possuiElevador),
                apartamentosPorAndar: handleConvertNumber(
                  dados.apartamentosPorAndar,
                ),
                quantidadeTorres: handleConvertNumber(dados.quantidadeTorres),
                anoConstrucao: handleConvertNumber(dados.anoConstrucao),
                porDoSol: dados.porDoSol,
                vagasGaragem: handleConvertNumber(dados.vagasGaragem),
                muroFrente: dados.muroFrente,
                muroFundo: dados.muroFundo,
                muroLados: dados.muroLados,
                areaConstruida: handleConvertNumber(dados.areaConstruida),
                areaTotalUniMedidaId: dados.areaTerrenoUnidadeMedidaId,
                areaTerreno: handleConvertNumber(dados.areaTerreno),
                areaPrivadaUniMedidaId: dados.areaConstruidaUnidadeMedidaId,
                quantidadeQuartos: handleConvertNumber(dados.quantidadeQuartos),
                sendoSuite: dados.sendoSuite,
                quantidadeSuites: handleConvertNumber(dados.quantidadeSuites),
                quantidadeBanheiros: handleConvertNumber(
                  dados.quantidadeBanheiros,
                ),
                piscina: handleSelectedCheck(dados.beneficios, 'PI'),
                agua: handleSelectedCheck(dados.possuiInfra, 'A'),
                luz: handleSelectedCheck(dados.possuiInfra, 'L'),
                vagasGaragemDescobertas: handleConvertNumber(
                  dados.vagasGaragemDescobertas,
                ),
                armarios: handleSelectedCheck(dados.beneficios, 'AR'),
                despensas: handleSelectedCheck(dados.beneficios, 'DE'),
                mobiliado: handleSelectedCheck(dados.beneficios, 'MO'),
                salasDeJantar: handleSelectedCheck(dados.beneficios, 'SJ'),
                closets: handleSelectedCheck(dados.beneficios, 'CL'),
                escritorios: handleSelectedCheck(dados.beneficios, 'ES'),
                lavabos: handleSelectedCheck(dados.beneficios, 'LA'),
                dces: handleSelectedCheck(dados.beneficios, 'DC'),
                banheirosServico: handleSelectedCheck(dados.beneficios, 'BS'),
                varandas: handleSelectedCheck(dados.beneficios, 'VA'),
                armarioCozinha: handleSelectedCheck(dados.beneficios, 'AC'),
                churrasqueiras: handleSelectedCheck(dados.beneficios, 'CH'),
                saloesFesta: handleSelectedCheck(dados.beneficios, 'SF'),
                saloesDeGinastica: handleSelectedCheck(dados.beneficios, 'SG'),
                quadraDeEsportes: handleSelectedCheck(dados.beneficios, 'QE'),
                quintal: handleSelectedCheck(dados.beneficios, 'QU'),
                copa: handleSelectedCheck(dados.beneficios, 'CO'),
                redeEsgoto: handleSelectedCheck(dados.beneficios, 'RE'),
                minhaCasaMinhaVida: handleSelectedCheck(dados.beneficios, 'MV'),
                negocioIndicado: dados.negocioIndicado,
                proximaAtualizacao: dados.proximaAtualizacao,
                frente: Number(dados.medidasFrente || 0),
                fundo: Number(dados.medidasFundos || 0),
                ladoDireito: Number(dados.medidasLadoDireito || 0),
                ladoEsquerdo: Number(dados.medidasLadoEsquerdo || 0),
                chanfro: Number(dados.medidasChanfro || 0),
                muroLadoDireito: dados.muroLadoDireito,
              },
              divulgacaoImovel: dataDivulgacoes,
            };

            await api
              .put(url.IMOVEL.EDITAR(), payload)
              .then((res) => {
                setIsLoading(false);
                generateInitialValue(res.data.data);
                setOpenModalConfirm(true);
              })
              .catch((err) => {
                setIsLoading(false);
                const { error } = err.response.data;
                if (error) {
                  for (const item in error) {
                    Notify('error', pt.comum.atencao, error[item]);
                  }
                }
              });
          }
        }
      })
      .catch((errorInfo) => {
        const { errorFields } = errorInfo;
        setIsLoading(false);
        console.error(errorInfo);
        window.scrollTo(1, 1);
        if (errorFields) {
          const fieldName = errorFields[0]?.name[0];
          const fieldProps = form.getFieldInstance(fieldName);
          fieldProps.focus();
        }
      });
  }

  function handleChangeTipoImovel(value) {
    const dados = dataTipoImovel.find((item) => item.id === value);
    setCondition(dados.descricao.toLowerCase());
    setSelectedTipoImovelId(value);

    setTimeout(() => form.validateFields(), 1000);
  }

  // BLOCO PARA VALIDAÇÕES DO ENDEREÇO
  async function onSearchLogradouro(value) {
    const logradouroId = localStorage.getItem('icaseLogradouroId');
    if (logradouroId) {
      setSelectedLogradouroId(logradouroId);
      setOnLoadLogradouro(localStorage.getItem('icaseLogradouroDsc'));

      form.setFieldsValue({ logradouroId });
      localStorage.removeItem('icaseLogradouroId');
      localStorage.removeItem('icaseLogradouroDsc');
    } else {
      setOnLoadLogradouro(value);
    }
  }

  async function onSearchFinalidade(value) {
    setOnLoadFinalidade(value);

    const finalidadeId = localStorage.getItem('icaseFinalidadeId');
    if (finalidadeId) {
      form.setFieldsValue({ finalidadeId, tipoImovelId: undefined });
      localStorage.removeItem('icaseFinalidadeId');

      setSelectedFinalidadeId(finalidadeId);

      setDisabledTipoImovel(true);
      setOnLoadTipoImovel(finalidadeId);
      setDisabledTipoImovel(false);
    }
  }

  async function onSearchTipoImovel() {
    onLoadTipoImovel();
    setDisabledTipoImovel(true);
    setOnLoadTipoImovel(selectedFinalidadeId);

    const tipoImovelId = localStorage.getItem('icaseTipoImovelId');
    if (tipoImovelId) {
      form.setFieldsValue({ tipoImovelId });
      localStorage.removeItem('icaseTipoImovelId');
    }

    setDisabledTipoImovel(false);
  }

  async function onChangeLogradouro(logradouroId) {
    if (logradouroId) {
      setSelectedLogradouroId(logradouroId);
      setExecUseEffect(!execUseEffect);
    } else {
      setOnLoadLogradouro('');
    }
  }

  async function handleEndereco(e, type) {
    let cep = e.currentTarget.value;
    cep = unMask(cep);
    cep = mask(cep, [type]);
    if (type === pattern.cep) form.setFieldsValue({ cep });

    if (cep.length === 10) {
      const unmaskedCEP = unMask(cep);
      setLoadingEndereco(true);
      await api
        .get(url.SERVICES.CONSULTA_CEP(unmaskedCEP))
        .then(async (res) => {
          const { data: dataEndereco, status } = res;

          if (status === 204) {
            Notify(
              'warning',
              pt.comum.atencao,
              'Nenhum logradouro encontrado com o CEP informado.',
            );
          } else if (dataEndereco) {
            onSetLatitudeLongitude(0, 0, `${dataEndereco?.bairro?.nome}, ${dataEndereco?.bairro?.cidade?.nome} - ${dataEndereco?.bairro?.cidade?.estadoId}`);

            setOnLoadLogradouro(dataEndereco.nome);
            form.setFieldsValue({
              logradouroId: dataEndereco.id,
              cep: dataEndereco.cep
                ? mask(dataEndereco.cep, [pattern.cep])
                : '',
              tipoLogradouro: dataEndereco.tipoLogradouro.descricao,
              bairro: dataEndereco.bairro.nome,
              cidade: dataEndereco.bairro.cidade.nome.toUpperCase(),
              cidadeId: dataEndereco?.bairro?.cidadeId,
              estado: dataEndereco.bairro.cidade.estadoId,
              pais: 'BRASIL',
            });

            onLoadBairro({
              cidadeId: dataEndereco?.bairro?.cidade?.id,
              nome: null,
            });
          }

          setLoadingEndereco(false);
        })
        .catch((err) => {
          setLoadingEndereco(false);
        });
    }
  }

  async function changeCondominio(value) {
    setLoadingEndereco(true);

    const dados = condominios?.find((item) => item.id === value);

    if (dados) {
      setOnLoadLogradouro(dados.logradouro.nome);

      form.setFieldsValue({
        complemento: dados.complemento,
        bairroComercialId: dados?.bairroComercialId,
        estado: dados.logradouro.bairro.cidade.estadoId,
        cidade: dados.logradouro.bairro.cidade.nome.toUpperCase(),
        bairro: dados.logradouro.bairro.nome,
        logradouroId: dados.logradouro.id,
        tipoLogradouro: dados.logradouro.tipoLogradouro.descricao,
        cep: dados.logradouro.cep
          ? mask(dados.logradouro.cep, [pattern.cep])
          : '',
        pais: 'BRASIL',
      });
    }

    onLoadBairro({ cidadeId: dados?.logradouro?.bairro?.cidade?.id, nome: null });

    setLoadingEndereco(false);
  }
  // #################################

  async function onSearchCorretorCaptacao(value) {
    await onLoadCorretores({ nome: value });
  }

  function changeTotalGaragens() {
    const { vagasGaragem, vagasGaragemDescobertas } = form.getFieldsValue([
      'vagasGaragem',
      'vagasGaragemDescobertas',
    ]);
    form.setFieldsValue({
      totalGaragens:
        Number(vagasGaragem ? vagasGaragem : 0) +
        Number(vagasGaragemDescobertas ? vagasGaragemDescobertas : 0),
    });
  }

  function changeSituacaoFinanceira(value) {
    setSituacaoFinanceira(value);
  }

  function changeOperacaoImobiliaria(value) {
    setOperacaoImobiliaria(value);
  }

  async function onSearchBairro(value) {
    const { cidadeId } = form.getFieldsValue(['cidadeId']);
    if (value) onLoadBairro({ cidadeId: cidadeId, nome: value });
  }

  async function onSyncImovel() {
    setLoadingSync(true);

    await api
      .post(`${url.IMOVEL.SYNC()}?imovelId=${imovelId}`)
      .then((res) => {
        setLoadingSync(false);
        setSincronizadoComDesktop(true);
        Notify('success', '', 'Imóvel sincronizado com sucesso!');
      })
      .catch((err) => {
        setLoadingSync(false);
        Notify('error', '', 'Erro na sincronização, tente novamente!');
      });
  }

  function handleMaskCurrency(e, field) {
    const maskedValue = formatedReal(e.target.value).replace('R$', '').trim();
    const numberValue = Number(
      maskedValue.replaceAll('.', '').replace(',', '.'),
    ).toFixed(2);
    form.setFieldsValue({ [field]: numberValue > 0 ? maskedValue : undefined });
  }

  function voltar() {
    keepFilter();
    window.close();
  }

  async function gerarDescricaoChatGPT() {
    setLoadGPT(true);

    const {
      operacaoImobiliaria,
      tipoImovelId,
      beneficios,
      quantidadeQuartos,
      quantidadeSuites,
      vagasGaragem,
      condominioId,
      bairro,
      cidade,
      areaConstruida,
      areaTerreno,
    } = form.getFieldsValue([
      'operacaoImobiliaria',
      'tipoImovelId',
      'beneficios',
      'quantidadeQuartos',
      'quantidadeSuites',
      'vagasGaragem',
      'condominioId',
      'bairro',
      'cidade',
      'areaConstruida',
      'areaTerreno',
    ]);

    const erros = [];

    if (!tipoImovelId) erros.push('Informe o Tipo de Imóvel.');

    if (!operacaoImobiliaria) erros.push('Informe a Operação Imobiliária');

    if (!areaConstruida && !areaTerreno)
      erros.push('Informe uma Área Total ou Área Privada');

    if (!bairro && !condominioId) erros.push('Informe um Bairro ou Condomínio');

    if (erros.length > 0) {
      for (let index = 0; index < erros.length; index++) {
        const erro = erros[index];
        Notify('warning', 'Oops..', erro);
      }
      setLoadGPT(false);
      return;
    }

    const operacaoDesc = enumOperacaoImobiliaria.find(
      (item) => item.id === operacaoImobiliaria,
    )?.nome;
    const tipoImovelDesc = dataTipoImovel.find(
      (item) => item.id === tipoImovelId,
    )?.descricao;

    const condominioDesc = condominios.find(
      (item) => item.id === condominioId,
    )?.nome;

    const beneficiosDesc = beneficios?.map((b) => {
      return enumBenfeitoriasImovel?.find((item) => item.value === b)?.label;
    });

    const payload = {
      message:
        `Crie um texto de ${operacaoDesc} para um ${tipoImovelDesc} com até 700 carácteres.
        O  ${tipoImovelDesc} tem ${areaConstruida || areaTerreno} m2
        ${quantidadeQuartos ? `, tem ${quantidadeQuartos} quartos ` : ''}
        ${quantidadeSuites ? `sendo ${quantidadeSuites} suítes ` : ''}

        ${vagasGaragem ? `${vagasGaragem} garagens cobertas` : ''}
        ${beneficiosDesc && beneficiosDesc?.length > 0
            ? `.Liste as benfeitorias abaixo em 1 item por linha: ${beneficiosDesc?.join(
              ',',
            )}`
            : ''
          }
        .Fale das principais vantagens de se morar no ${condominioDesc
            ? `condomínio ${condominioDesc} que fica situado no bairro ${bairro} em ${cidade}.`
            : `bairro ${bairro} em ${cidade}.`
          }.
        Convença o leitor a entrar em contato. Crie tags para relevância nas redes sociais.`
          .replace(/(\r\n|\n|\r)/gm, '')
          .replace(/\s+/g, ' ')
          .trim(),
    };

    console.log('Chat GPT => ', payload.message);

    try {
      await api
        .post('ChatGPT', payload)
        .then((res) => {
          const { adContent } = res?.data;
          form.setFieldsValue({
            caracteristicas: `${adContent
              ?.join('\r\n')
              ?.trimStart()}\r\nAgende visita sem compromisso.\r\nValores sujeito a alteração sem aviso prévio, consulte disponibilidade.`,
          });
        })
        .catch(() => {
          Notify(
            'error',
            pt.comum.atencao,
            'Não foi possível gerar a descrição',
          );
        });
    } catch (e) {
      Notify('error', pt.comum.atencao, 'Não foi possível gerar a descrição');
    } finally {
      setLoadGPT(false);
    }
  }

  return (
    <div className="px-4">
      <Row>
        <ColCt>
          {!visibleForm && <SkeletonTableBasic cardShadowless />}
          {visibleForm && (
            <>
              <Form
                form={form}
                initialValues={initialValues}
                scrollToFirstError>
                <Row>
                  <ColCt>
                    <CardHeader className="pl-0 mb-4">
                      <h5 className="title">Dados básicos</h5>
                    </CardHeader>
                  </ColCt>
                </Row>
                <Row>
                  {modo === MODO.EDITAR && (
                    <ColCt xs="12" md="3">
                      <p className="heading-small text-muted mb-2">
                        <small>Código do imóvel: </small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('codImovel')}
                        name="codImovel">
                        <Input disabled />
                      </FormItemCt>
                    </ColCt>
                  )}

                  {showExternalCode && modo === MODO.EDITAR && (
                    <ColCt xs="12" md="3">
                      <p className="heading-small text-muted mb-2">
                        <small>Cód. Externo</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('codigoExterno')}
                        name="codigoExterno">
                        <Input disabled />
                      </FormItemCt>
                    </ColCt>
                  )}

                  {!sincronizadoComDesktop && (
                    <ColCt xs="12" md="3">
                      <Button
                        onClick={onSyncImovel}
                        icon
                        labelPosition="left"
                        size="tiny"
                        className="mb-1"
                        fluid
                        color="violet"
                        isloading={loadingSync.toString()}
                        loadtext="Sincronizando"
                        type="button">
                        <Icon name="sync" />
                        Sincronizar Imóvel
                      </Button>
                    </ColCt>
                  )}
                </Row>
                <Row>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Operação Imobiliária</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="operacaoImobiliaria"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a operação imobiliária',
                        },
                      ]}>
                      <Select
                        placeholder=" Operação Imobiliária"
                        onChange={changeOperacaoImobiliaria}>
                        {enumOperacaoImobiliaria.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Finalidade</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="finalidadeId"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a finalidade',
                        },
                      ]}>
                      <Select
                        placeholder=" Finalidade"
                        onChange={handleFinalidade}>
                        {dataFinalidades.map((item) => (
                          <Option key={item.id}>{item.nome}</Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Tipo do Imóvel</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="tipoImovelId"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o tipo de imóvel',
                        },
                      ]}>
                      <Select
                        placeholder=" Tipo de imóvel"
                        onChange={handleChangeTipoImovel}
                        disabled={disabledTipoImovel}>
                        {dataTipoImovel.map((item) => (
                          <Option key={item.id}>{item.descricao}</Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Situação do Imóvel</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="situacao"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a situação do imóvel',
                        },
                      ]}>
                      <Select placeholder=" Situação">
                        {enumSituacaoImovel.map((item) => (
                          <Option key={item.id}>{item.nome}</Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt>
                    <CardHeader className="pl-0 mb-4">
                      <Divider />
                      <h5 className="title">Endereço/Localização</h5>
                    </CardHeader>
                  </ColCt>
                </Row>
                {loadingEndereco && <SkeletonTableBasic cardShadowless />}
                {!loadingEndereco && (
                  <Fragment>
                    <Row>
                      <ColCt
                        hide={fieldIsHidden('condominioId')}
                        xs="12"
                        md="4"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Condomínio/Empreendimento</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('condominioId')}
                          name="condominioId">
                          <Select
                            placeholder=" Condomínio/Empreendimento"
                            defaultActiveFirstOption={true}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                ) >= 0
                            }
                            allowClear
                            onSearch={onSearchCondominio}
                            onChange={changeCondominio}
                            onClear={changeCondominio}>
                            <>
                              {condominios?.map((item) => (
                                <Option key={item.id} value={item.id}>
                                  {`${item.nome} - ${item?.logradouro?.bairro?.nome} - ${item?.logradouro?.bairro?.cidade?.nome} - ${item?.logradouro?.bairro?.cidade?.estadoId}`}
                                </Option>
                              ))}
                            </>
                          </Select>
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('condominioId')}
                        xs="12"
                        md="1"
                        className="my-2">
                        <p className="heading-small text-muted mb-1 text-white">
                          <small>.</small>
                        </p>
                        <Tooltip
                          placement="topRight"
                          title={'Cadastrar Condomínio'}>
                          <Button
                            type="button"
                            style={{ width: '100%' }}
                            onClick={() => setModalCondominioOpen(true)}
                            icon
                            color="violet">
                            <Icon name="plus" />
                          </Button>
                        </Tooltip>
                      </ColCt>
                      <ColCt xs="12" md="2" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>CEP</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('cep')}
                          className="my-2"
                          name="cep">
                          <Input
                            placeholder=" CEP"
                            maxLength={10}
                            onChange={(e) => handleEndereco(e, pattern.cep)}
                          />
                        </FormItemCt>
                      </ColCt>
                      <ColCt xs="12" md="4" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Logradouro</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('logradouroId')}
                          className="my-2"
                          name="logradouroId">
                          <Select
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children[0] &&
                              option.children[0]
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                ) >= 0
                            }
                            placeholder=" Logradouro"
                            onSearch={onSearchLogradouro}
                            onChange={onChangeLogradouro}
                            onClear={onChangeLogradouro}>
                            {dataLogradouros?.map((item) => (
                              <Option key={item.id}>
                                {item.nome}
                                {item?.bairro?.nome &&
                                  `, Bairro: ${item?.bairro?.nome}`}
                              </Option>
                            ))}
                          </Select>
                        </FormItemCt>
                      </ColCt>
                      <ColCt xs="12" md="1" className="my-2">
                        <p className="heading-small text-muted mb-1 text-white">
                          <small>.</small>
                        </p>
                        <Tooltip
                          placement="topRight"
                          title={'Cadastrar Logradouro'}>
                          <Button
                            style={{ width: '100%' }}
                            onClick={() => setModalLogradouroOpen(true)}
                            icon
                            type="button"
                            disabled={!roles.isDiretor && !roles.isGerente}
                            color="violet">
                            <Icon name="plus" />
                          </Button>
                        </Tooltip>
                      </ColCt>
                    </Row>
                    <Row>
                      <ColCt xs="12" md="2" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Logradouro</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('tipoLogradouro')}
                          className="my-2"
                          name="tipoLogradouro">
                          <Input placeholder=" Tipo de Logradouro" disabled />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('bairro')}
                        xs="12"
                        md="4"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Bairro</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsHidden('')}
                          className="my-2"
                          name="bairro">
                          <Input placeholder=" Bairro" disabled />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('bairroComercialId')}
                        xs="12"
                        md="4"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Bairro Comercial</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('bairroComercialId')}
                          className="my-2"
                          name="bairroComercialId">
                          <Select
                            getPopupContainer={(node) => node.parentNode}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                                .indexOf(
                                  input
                                    .toLowerCase()
                                    .normalize('NFD')
                                    .replace(/[\u0300-\u036f]/g, ''),
                                ) >= 0
                            }
                            placeholder={
                              loadingEndereco ? ' Carregando...' : ' Bairro'
                            }
                            allowClear
                            onSearch={onSearchBairro}
                            onClear={onSearchBairro}
                            disabled={loadingEndereco}>
                            {dataBairros.map((item) => (
                              <Option key={item.id}>{item.nome}</Option>
                            ))}
                          </Select>
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('cidade')}
                        xs="12"
                        md="2"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Cidade</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('cidade')}
                          className="my-2"
                          name="cidade">
                          <Input placeholder=" Cidade" disabled />
                        </FormItemCt>
                      </ColCt>
                    </Row>
                    <Row>
                      <ColCt
                        hide={fieldIsHidden('estado')}
                        xs="12"
                        md="2"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Estado</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('estado')}
                          className="my-2"
                          name="estado">
                          <Input placeholder=" Estado" disabled />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('pais')}
                        xs="12"
                        md="3"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>País</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('pais')}
                          className="my-2"
                          name="pais">
                          <Input disabled placeholder=" País" />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('complemento')}
                        xs="12"
                        sm="6"
                        md="3"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Complemento</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('complemento')}
                          className="my-2"
                          name="complemento">
                          <Input maxLength={100} placeholder=" Complemento" />
                        </FormItemCt>
                      </ColCt>
                      <ColCt xs="6" md="2" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Latitude</small>
                        </p>
                        <FormItemCt
                          isRequired={true}
                          className="my-2"
                          name="latitude">
                          <Input maxLength={100} placeholder=" latitude" />
                        </FormItemCt>
                      </ColCt>
                      <ColCt xs="6" md="2" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Longitude</small>
                        </p>
                        <FormItemCt
                          isRequired={true}
                          className="my-2"
                          name="longitude">
                          <Input maxLength={100} placeholder=" longitude" />
                        </FormItemCt>
                      </ColCt>
                    </Row>

                    <Row>
                      <GoogleMaps
                        latitude={latLng.latitude}
                        longitude={latLng.longitude}
                        suggestionPlace={latLng.suggestionPlace}
                        setLatitudeLongitude={onSetLatitudeLongitude}
                      />
                    </Row>
                    <Row>
                      <ColCt
                        hide={fieldIsHidden('numero')}
                        xs="12"
                        md="2"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Número</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('numero')}
                          className="my-2"
                          name="numero">
                          <Input placeholder=" Número" />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('loteApto')}
                        xs="12"
                        md="2"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Lote/Apto</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('loteApto')}
                          className="my-2"
                          name="loteApto">
                          <Input placeholder=" Lote/Apto" />
                        </FormItemCt>
                      </ColCt>
                      <ColCt
                        hide={fieldIsHidden('quadraBloco')}
                        xs="12"
                        md="2"
                        className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Quadra/Bloco</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('quadraBloco')}
                          className="my-2"
                          name="quadraBloco">
                          <Input placeholder=" Quadra/Bloco" />
                        </FormItemCt>
                      </ColCt>
                    </Row>
                  </Fragment>
                )}
                <Row>
                  <ColCt>
                    <CardHeader className="pl-0 mb-4">
                      <Divider />
                      <h5 className="title">Dados do imóvel</h5>
                    </CardHeader>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('tituloAmigavel')}
                    xs="12"
                    md="12"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Título Amigável</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('tituloAmigavel')}
                      className="my-2"
                      name="tituloAmigavel">
                      <Input maxLength={3000} placeholder=" Título amigável" />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('areaTerreno')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Área Total</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('areaTerreno')}
                      className="my-2"
                      name="areaTerreno">
                      <Input placeholder=" Área Total" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('areaTerreno')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Unidade</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      isRequired={fieldIsRequired('areaTerreno')}
                      name="areaTerrenoUnidadeMedidaId">
                      <Select
                        placeholder=" Unidade"
                        showSearch
                        filterOption={false}
                        defaultValue={
                          modo === MODO.CADASTRAR
                            ? dataUnidadeMedida.find(
                              (item) => item.descricao.toLowerCase() === 'm2',
                            )?.id
                            : undefined
                        }>
                        {dataUnidadeMedida.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.descricao}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('areaConstruida')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Área Privada</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('areaConstruida')}
                      className="my-2"
                      name="areaConstruida">
                      <Input
                        placeholder=" Área Privada"
                        type="number"
                        min={0}
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('areaConstruida')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Unidade</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="areaConstruidaUnidadeMedidaId"
                      isRequired={fieldIsRequired('areaConstruida')}>
                      <Select
                        placeholder=" Unidade"
                        showSearch
                        filterOption={false}
                        defaultValue={
                          modo === MODO.CADASTRAR
                            ? dataUnidadeMedida.find(
                              (item) => item.descricao.toLowerCase() === 'm2',
                            )?.id
                            : undefined
                        }>
                        {dataUnidadeMedida.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.descricao}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('porDoSol')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Posição do Sol</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('porDoSol')}
                      className="my-2"
                      name="porDoSol">
                      <Select placeholder=" Posição do Sol">
                        <Option value="M">Manhã</Option>
                        <Option value="T">Tarde</Option>
                        <Option value="I">Intermediário</Option>
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('vagasGaragem')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Garagem Coberta(s)</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('vagasGaragem')}
                      className="my-2"
                      name="vagasGaragem">
                      <Input
                        placeholder=" Garagem Coberta(s)"
                        type="number"
                        min={0}
                        onChange={changeTotalGaragens}
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('vagasGaragemDescobertas')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Garagem Descoberta(s)</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('vagasGaragemDescobertas')}
                      className="my-2"
                      name="vagasGaragemDescobertas">
                      <Input
                        placeholder=" Garagem Descoberta(s)"
                        type="number"
                        min={0}
                        onChange={changeTotalGaragens}
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('totalGaragens')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Total Garagem(ns)</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('totalGaragens')}
                      className="my-2"
                      name="totalGaragens">
                      <Input disabled />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('quantidadeQuartos')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Quarto(s)</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      isRequired={fieldIsRequired('quantidadeQuartos')}
                      name="quantidadeQuartos">
                      <Input placeholder=" Quarto(s)" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('sendoSuite')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>s/ Suítes</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('sendoSuite')}
                      className="my-2"
                      name="sendoSuite">
                      <Select placeholder=" s/ Suítes">
                        <Option value="S/">S/</Option>
                        <Option value="+">+</Option>
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('quantidadeSuites')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Qtd. Suítes</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('quantidadeSuites')}
                      className="my-2"
                      name="quantidadeSuites">
                      <Input placeholder=" Qtd. Suítes" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('quantidadeBanheiros')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Bwc. Social</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('quantidadeBanheiros')}
                      className="my-2"
                      name="quantidadeBanheiros">
                      <Input placeholder=" BWC Social" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('quantidadeTorres')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Qtd. Torres</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('quantidadeTorres')}
                      className="my-2"
                      name="quantidadeTorres">
                      <Input placeholder=" Qtd. Torres" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('qtdAndares')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Qtd. Andares</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('qtdAndares')}
                      className="my-2"
                      name="qtdAndares">
                      <Input
                        placeholder=" Qtd. Andares"
                        type="number"
                        min={0}
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('nroAndar')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Nº Andar</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('nroAndar')}
                      className="my-2"
                      name="nroAndar">
                      <Input placeholder=" Nº Andar" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('apartamentosPorAndar')}
                    xs="12"
                    md="1"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Apto. p/ Andar</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('apartamentosPorAndar')}
                      className="my-2"
                      name="apartamentosPorAndar">
                      <Input placeholder=" Apto. Andar" type="number" min={0} />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('possuiElevador')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Elevador(es)</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('possuiElevador')}
                      className="my-2"
                      name="possuiElevador">
                      <Input
                        placeholder=" Elevador(es)"
                        type="number"
                        min={0}
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('andarTerreo')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Andar Térreo</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('andarTerreo')}
                      className="my-2"
                      name="andarTerreo">
                      <Select placeholder=" Andar Térreo">
                        <Option value="S">Sim</Option>
                        <Option value="N">Não</Option>
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('anoConstrucao')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Ano Construção</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('anoConstrucao')}
                      className="my-2"
                      name="anoConstrucao">
                      <Input
                        placeholder=" Ano Construção"
                        type="number"
                        min={0}
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('beneficios')}
                    xs="12"
                    md="12"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Benfeitorias/Benefícios</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('beneficios')}
                      className="my-2"
                      name="beneficios">
                      {/* <Checkbox.Group options={enumBenfeitoriasImovel} /> */}
                      <Checkbox.Group style={{ widht: '100%' }} className="mx-2">
                        <Row>
                          {enumBenfeitoriasImovel.map((item) => (
                            <ColCt key={item.value} xs="12" md="2">
                              <Checkbox
                                value={item.value}
                                style={{ lineHeight: '30px' }}>
                                {item.label}
                              </Checkbox>
                            </ColCt>
                          ))}
                        </Row>
                      </Checkbox.Group>
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Situação Financeira</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="situacaoFinanceira"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a situação financeira',
                        },
                      ]}>
                      <Select
                        placeholder=" Situação Financeira"
                        onChange={changeSituacaoFinanceira}>
                        {enumSituacaoFinanceiraImovel.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Situação Documental</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="situacaoDocumental"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a situação documental',
                        },
                      ]}>
                      <Select placeholder=" Situação Documental">
                        {enumSituacaoDocumentalImovel.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('aceitaPermuta')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Aceita Permuta</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('aceitaPermuta')}
                      className="my-2"
                      name="aceitaPermuta">
                      <Select placeholder=" Aceita Permuta">
                        <Option value="S">Sim</Option>
                        <Option value="N">Não</Option>
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('padraoSocial')}
                    xs="12"
                    md="3"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Padrão Social</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="padraoSocial"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o padrão social',
                        },
                      ]}>
                      <Select placeholder=" Padrão Social">
                        {enumPadraoSocialImovel.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.nome}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                </Row>
                {situacaoFinanceira === 2 && (
                  <Row>
                    <ColCt
                      hide={fieldIsHidden('prestacaoValor')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Valor da Prestação</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('prestacaoValor')}
                        className="my-2"
                        name="prestacaoValor">
                        <Input
                          placeholder=" Valor da Prestação"
                          onChange={(e) =>
                            handleMaskCurrency(e, 'prestacaoValor')
                          }
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('prestacoesFaltantes')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Prestações Faltantes</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('prestacoesFaltantes')}
                        className="my-2"
                        name="prestacoesFaltantes">
                        <Input
                          placeholder=" Prestações Faltantes"
                          type="number"
                          min={0}
                        />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('saldoDevedor')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Saldo Devedor</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('saldoDevedor')}
                        className="my-2"
                        name="saldoDevedor">
                        <Input
                          placeholder=" Saldo Devedor"
                          onChange={(e) =>
                            handleMaskCurrency(e, 'saldoDevedor')
                          }
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                  </Row>
                )}
                <Row>
                  <ColCt
                    hide={fieldIsHidden('procedimentoVisita')}
                    xs="12"
                    md="12"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Uso interno</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="procedimentoVisita"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar!',
                        },
                      ]}>
                      <TextArea
                        placeholder="Ex: procedimentos de visita"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={2000}
                        showCount
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('caracteristicas')}
                    xs="12"
                    md="12"
                    className="my-2">
                    <Row className="px-2" style={{ alignItems: 'flex-end' }}>
                      <ColCt xs="12" md="8" xl="9">
                        <p className="heading-small text-muted">
                          <small>Descrição - Sites/Portais</small>
                        </p>
                      </ColCt>
                      <ColCt xs="12" md="4" xl="3">
                        <Tooltip
                          placement="top"
                          title={
                            'Gerar descrição do imóvel usando a inteligência artificial do ChatGPT.'
                          }>
                          <Button
                            icon
                            labelPosition="left"
                            size="medium"
                            fluid
                            color="olive"
                            onClick={gerarDescricaoChatGPT}
                            loadtext="Gerando texto..."
                            loadColor="green"
                            isloading={loadGPT ? 'true' : 'false'}>
                            <div className="icon p-2">
                              <Image
                                size="mini"
                                verticalAlign="middle"
                                src={chatgpt_icon}
                                wrapped
                              />
                            </div>
                            Gerar Descrição
                          </Button>
                        </Tooltip>
                      </ColCt>
                    </Row>
                    <FormItemCt
                      isRequired={fieldIsRequired('caracteristicas')}
                      className="my-2"
                      name="caracteristicas">
                      <TextArea
                        placeholder=" Característica descritivas"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={2000}
                        showCount
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('url')}
                    xs="12"
                    md="6"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>URL Youtube</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('url')}
                      className="my-2"
                      name="url">
                      <Input
                        maxLength={5000}
                        placeholder=" URL Youtube"
                        type="url"
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('url360')}
                    xs="12"
                    md="6"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>URL Tour 360</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('url360')}
                      className="my-2"
                      name="url360">
                      <Input
                        maxLength={100}
                        placeholder=" URL Tour 360"
                        type="url"
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('negocioIndicado')}
                    xs="12"
                    md="12"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Negócio indicado</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('negocioIndicado')}
                      className="my-2"
                      name="negocioIndicado">
                      <Input maxLength={100} placeholder=" Negócio indicado" />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt>
                    <CardHeader className="pl-0 mb-4">
                      <Divider />
                      <h5 className="title">Dados administrativos</h5>
                    </CardHeader>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Corretor Captação</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="corretorCaptacaoId"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar o corretor de captação',
                        },
                      ]}>
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(
                              input
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, ''),
                            ) >= 0
                        }
                        placeholder=" Corretor Captação"
                        allowClear
                        onSearch={onSearchCorretorCaptacao}
                        onClear={onSearchCorretorCaptacao}>
                        {dataCorretores.map((item) => (
                          <Option key={item.id} disabled={item.isSeparator}>
                            {item?.nome?.toString()}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="4" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>2º Corretor Captação</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('corretorCaptacao2Id')}
                      className="my-2"
                      name="corretorCaptacao2Id">
                      <Select
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .normalize('NFD')
                            .replace(/[\u0300-\u036f]/g, '')
                            .indexOf(
                              input
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, ''),
                            ) >= 0
                        }
                        placeholder=" 2º Corretor Captação"
                        allowClear
                        onSearch={onSearchCorretorCaptacao}
                        onClear={onSearchCorretorCaptacao}>
                        {dataCorretores.map((item) => (
                          <Option key={item.id} disabled={item.isSeparator}>
                            {item?.nome?.toString()}
                          </Option>
                        ))}
                      </Select>
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="2" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Data de Captação</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      initialValue={moment()}
                      name="captacao"
                      rules={[
                        {
                          required: true,
                          message: 'Favor informar a data de captação',
                        },
                      ]}>
                      <DatePicker
                        locale={locale}
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder=" Data de Captação"
                      />
                    </FormItemCt>
                  </ColCt>
                  {modo === MODO.EDITAR && (
                    <ColCt xs="12" md="2" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Data de Criaçãos</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('dataCriacao')}
                        className="my-2"
                        name="dataCriacao">
                        <DatePicker
                          locale={locale}
                          format={'DD/MM/YYYY HH:mm'}
                          style={{ width: '100%' }}
                          disabled
                        />
                      </FormItemCt>
                    </ColCt>
                  )}
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('proximaAtualizacao')}
                    xs="12"
                    md="3"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Próxima Atualização</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      initialValue={moment().add(30, 'days')}
                      name="proximaAtualizacao"
                      rules={[
                        {
                          required: true,
                          message:
                            'Favor informar a próxima data de atualização',
                        },
                      ]}>
                      <DatePicker
                        locale={locale}
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder=" Próxima Atualização"
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('vencimentoAutorizacao')}
                    xs="12"
                    md="3"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Vencimento da Autorização</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="vencimentoAutorizacao"
                      rule={[
                        {
                          require: checkedAutorizacao,
                          message: 'Favor informar o vencimento da autorização',
                        },
                      ]}>
                      <DatePicker
                        locale={locale}
                        format={dateFormat}
                        style={{ width: '100%' }}
                        placeholder=" Vencimento da Autorização"
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt xs="12" md="3" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Situação</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="situacoes"
                      initialValue={defaultValueSituacoes}>
                      <Checkbox.Group
                        options={situacoes}
                        onChange={handleChangeSituacoes}
                      />
                    </FormItemCt>
                  </ColCt>
                  {imovelOcupado && (
                    <ColCt xs="12" md="3" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Oculpado Por</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('ocupadoPor')}
                        className="my-2"
                        name="ocupadoPor">
                        <Select placeholder=" Ocupado Por">
                          <Option value="P">Proprietário</Option>
                          <Option value="I">Inquilino</Option>
                        </Select>
                      </FormItemCt>
                    </ColCt>
                  )}
                </Row>
                <Row>
                  <ColCt
                    hide={fieldIsHidden('comissaoVenda')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Comissão de Venda</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('comissaoVenda')}
                      className="my-2"
                      name="comissaoVenda">
                      <Input
                        placeholder=" Comissão de Venda"
                        onChange={(e) => handleMaskCurrency(e, 'comissaoVenda')}
                        prefix="R$"
                      />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('numeroChave')}
                    xs="12"
                    md="3"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Número da Chave</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('numeroChave')}
                      className="my-2"
                      name="numeroChave">
                      <Input placeholder=" N. Da Chave" type="number" />
                    </FormItemCt>
                  </ColCt>
                  <ColCt
                    hide={fieldIsHidden('chavesNaEmpresa')}
                    xs="12"
                    md="2"
                    className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Chave na Empresa?</small>
                    </p>
                    <FormItemCt
                      className="my-2"
                      name="chavesNaEmpresa"
                      valuePropName="checked">
                      <Switch
                        className="my-2"
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <>
                  <Row>
                    <ColCt
                      hide={fieldIsHidden('iptu')}
                      xs="12"
                      md="4"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>IPTU</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('iptu')}
                        className="my-2"
                        name="iptu">
                        <Input placeholder=" IPTU" />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('unidadeConsumidoraEnergia')}
                      xs="12"
                      md="4"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Unidade Consumidora Energia</small>
                      </p>
                      <FormItemCt
                        className="my-2"
                        name="unidadeConsumidoraEnergia">
                        <Input placeholder=" Unidade Consumidora Energia" />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('unidadeConsumidoraAgua')}
                      xs="12"
                      md="4"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Unidade Consumidora Água/Esgoto</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('unidadeConsumidoraAgua')}
                        className="my-2"
                        name="unidadeConsumidoraAgua">
                        <Input placeholder=" Unidade Consumidora Água/Esgoto" />
                      </FormItemCt>
                    </ColCt>
                  </Row>
                  <Row>
                    <ColCt
                      hide={fieldIsHidden('condicoesPgtoIptu')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Condições Pgto IPTU/ITR</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('condicoesPgtoIptu')}
                        className="my-2"
                        name="condicoesPgtoIptu">
                        <Select
                          onChange={() => {
                            form.validateFields();
                          }}
                          placeholder="Condições Pgto IPTU/ITR">
                          {enumCondicoesPgtoIPTU.map((item) => (
                            <Option key={item.id}>{item.nome}</Option>
                          ))}
                        </Select>
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('valorIptu')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Valor IPTU</small>
                      </p>
                      <FormItemCt
                        rules={[
                          // eslint-disable-next-line no-empty-pattern
                          ({ }) => ({
                            validator(rule, value) {
                              const fieldValue =
                                form.getFieldValue('condicoesPgtoIptu');
                              if (!value && fieldValue !== '3') {
                                return Promise.reject('Favor informar o IPTU');
                              }

                              return Promise.resolve();
                            },
                          }),
                        ]}
                        className="my-2"
                        name="valorIptu">
                        <Input
                          placeholder=" Valor IPTU"
                          onChange={(e) => handleMaskCurrency(e, 'valorIptu')}
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('valorSeguroFianca')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Valor Seguro/Fiança</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('valorSeguroFianca')}
                        className="my-2"
                        name="valorSeguroFianca">
                        <Input
                          placeholder=" Valor Seguro/Fiança"
                          onChange={(e) =>
                            handleMaskCurrency(e, 'valorSeguroFianca')
                          }
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                    <ColCt
                      hide={fieldIsHidden('valorSeguroIncendio')}
                      xs="12"
                      md="3"
                      className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Valor Seguro/Incêndio</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('valorSeguroIncendio')}
                        className="my-2"
                        name="valorSeguroIncendio">
                        <Input
                          placeholder=" Valor Seguro/Incêndio"
                          onChange={(e) =>
                            handleMaskCurrency(e, 'valorSeguroIncendio')
                          }
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                  </Row>
                </>
                {condition && finalidade && finalidade.includes('rural') && (
                  <Row>
                    <ColCt>
                      <Divider plain orientation="left">
                        Dados Rurais
                      </Divider>
                    </ColCt>
                  </Row>
                )}
                {condition &&
                  finalidade &&
                  (finalidade.includes('rural') ||
                    condition.includes('terreno')) && (
                    <Row>
                      <ColCt xs="12" md="12" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Medidas em Metros Linear</small>
                        </p>
                        <Row className="mx-2">
                          <ColCt xs="12" md="2" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Frente</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('medidasFrente')}
                              className="my-2"
                              name="medidasFrente">
                              <Input
                                placeholder=" Frente"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="2" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Fundos</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('medidasFundos')}
                              className="my-2"
                              name="medidasFundos">
                              <Input
                                placeholder=" Fundos"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="2" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Lado Direito</small>
                            </p>
                            <FormItemCt
                              className="my-2"
                              name="medidasLadoDireito">
                              <Input
                                placeholder=" Lado Direito"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="2" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Lado Esquerdo</small>
                            </p>
                            <FormItemCt
                              className="my-2"
                              name="medidasLadoEsquerdo">
                              <Input
                                placeholder=" Lado Esquerdo"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="2" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Chanfro</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('medidasChanfro')}
                              className="my-2"
                              name="medidasChanfro">
                              <Input
                                placeholder=" Chanfro"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                        </Row>
                      </ColCt>
                    </Row>
                  )}
                {condition &&
                  finalidade &&
                  (finalidade.includes('rural') ||
                    condition.includes('terreno')) && (
                    <Row>
                      <ColCt xs="12" md="12" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Confrontamentos</small>
                        </p>
                        <Row className="mx-2">
                          <ColCt xs="12" md="3" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Frente</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('muroFrente')}
                              className="my-2"
                              name="muroFrente">
                              <Input maxLength={100} placeholder=" Frente" />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="3" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Fundos</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('muroFundo')}
                              className="my-2"
                              name="muroFundo">
                              <Input maxLength={100} placeholder=" Fundos" />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="3" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Lado Direito</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('muroLadoDireito')}
                              className="my-2"
                              name="muroLadoDireito">
                              <Input
                                maxLength={100}
                                placeholder=" Lado Direito"
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="3" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Lado Esquerdo</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('muroLados')}
                              className="my-2"
                              name="muroLados">
                              <Input
                                maxLength={100}
                                placeholder=" Lado Esquerdo"
                              />
                            </FormItemCt>
                          </ColCt>
                        </Row>
                      </ColCt>
                    </Row>
                  )}
                {condition &&
                  finalidade &&
                  finalidade.toLocaleLowerCase().includes('rural') && (
                    <Row>
                      <ColCt xs="12" md="12" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Áreas</small>
                        </p>
                        <Row className="mx-2">
                          <ColCt xs="12" md="1" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Alqueires</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('alqueires')}
                              className="my-2"
                              name="alqueires">
                              <Input
                                placeholder=" Alqueires"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="1" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Hectares</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('hectares')}
                              className="my-2"
                              name="hectares">
                              <Input
                                placeholder=" Hectares"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="1" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Aberta</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('aberta')}
                              className="my-2"
                              name="aberta">
                              <Input
                                placeholder=" Aberta"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="1" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Reserva</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('preservacao')}
                              className="my-2"
                              name="preservacao">
                              <Input
                                placeholder=" Reserva"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          <ColCt xs="12" md="1" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>A formar</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('aFormar')}
                              className="my-2"
                              name="aFormar">
                              <Input
                                placeholder=" A formar"
                                type="number"
                                min={0}
                              />
                            </FormItemCt>
                          </ColCt>
                          {/*  <ColCt  xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Tipo de Solo</small>
                        </p>
                        <FormItemCt isRequired={fieldIsRequired('')}className="my-2" name="tipoSoloId">
                          <Select placeholder=" Tipo de Solo">
                            {dataTipoSolo.map((item) => (
                              <Option key={item.id} value={item.id}>
                                {item.nome}
                              </Option>
                            ))}
                          </Select>
                        </FormItemCt>
                      </ColCt> */}
                          <ColCt xs="12" md="4" className="my-2">
                            <p className="heading-small text-muted mb-2">
                              <small>Possui</small>
                            </p>
                            <FormItemCt
                              isRequired={fieldIsRequired('possuiInfra')}
                              className="my-2"
                              name="possuiInfra">
                              <Checkbox.Group options={possuiInfra} />
                            </FormItemCt>
                          </ColCt>
                        </Row>
                      </ColCt>
                    </Row>
                  )}
                {condition && finalidade && finalidade.includes('rural') && (
                  <Row>
                    <ColCt xs="12" md="4" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Distâncias (KM)</small>
                      </p>
                      <Row className="mx-2">
                        <ColCt xs="12" md="4" className="my-2">
                          <p className="heading-small text-muted mb-2">
                            <small>Sede Município</small>
                          </p>
                          <FormItemCt
                            isRequired={fieldIsRequired('distanciaSede')}
                            className="my-2"
                            name="distanciaSede">
                            <Input
                              placeholder=" Sede Município"
                              type="number"
                              min={0}
                            />
                          </FormItemCt>
                        </ColCt>
                        <ColCt xs="12" md="4" className="my-2">
                          <p className="heading-small text-muted mb-2">
                            <small>Asfalto</small>
                          </p>
                          <FormItemCt
                            isRequired={fieldIsRequired('distanciaAsfalto')}
                            className="my-2"
                            name="distanciaAsfalto">
                            <Input
                              placeholder=" Asfalto"
                              type="number"
                              min={0}
                            />
                          </FormItemCt>
                        </ColCt>
                        <ColCt xs="12" md="4" className="my-2">
                          <p className="heading-small text-muted mb-2">
                            <small>Capital</small>
                          </p>
                          <FormItemCt
                            isRequired={fieldIsRequired('distanciaCapital')}
                            className="my-2"
                            name="distanciaCapital">
                            <Input
                              placeholder=" Capital"
                              type="number"
                              min={0}
                            />
                          </FormItemCt>
                        </ColCt>
                      </Row>
                    </ColCt>
                    <ColCt xs="12" md="4" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Benfeitorias</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('benfeitorias')}
                        className="my-2"
                        name="benfeitorias">
                        <TextArea
                          placeholder=" Benfeitorias"
                          autoSize={{ minRows: 3, maxRows: 3 }}
                          maxLength={1000}
                        />
                      </FormItemCt>
                    </ColCt>
                    <ColCt xs="12" md="4" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Dados do Campo</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('dadosCampo')}
                        className="my-2"
                        name="dadosCampo">
                        <TextArea
                          placeholder=" Dados do Campo"
                          autoSize={{ minRows: 3, maxRows: 3 }}
                          maxLength={1000}
                        />
                      </FormItemCt>
                    </ColCt>
                  </Row>
                )}
                <Row>
                  <ColCt>
                    <CardHeader className="pl-0 mb-4">
                      <Divider />
                      <h5 className="title">Dados financeiros</h5>
                    </CardHeader>
                  </ColCt>
                </Row>
                <Row>
                  {(operacaoImobiliaria === 2 || operacaoImobiliaria === 3) && (
                    <>
                      <ColCt xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor de Venda</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('valorVenda')}
                          className="my-2"
                          name="valorVenda">
                          <Input
                            placeholder=" Valor de Venda"
                            onChange={(e) =>
                              handleMaskCurrency(e, 'valorVenda')
                            }
                            prefix="R$"
                          />
                        </FormItemCt>
                      </ColCt>
                      <ColCt xs="12" md="3" className="my-2">
                        <p className="heading-small text-muted mb-2">
                          <small>Valor do Ágio</small>
                        </p>
                        <FormItemCt
                          isRequired={fieldIsRequired('valorAgio')}
                          className="my-2"
                          name="valorAgio">
                          <Input
                            placeholder=" Valor do Ágio"
                            onChange={(e) => handleMaskCurrency(e, 'valorAgio')}
                            prefix="R$"
                          />
                        </FormItemCt>
                      </ColCt>
                    </>
                  )}
                  {(operacaoImobiliaria === 1 || operacaoImobiliaria === 3) && (
                    <ColCt xs="12" md="3" className="my-2">
                      <p className="heading-small text-muted mb-2">
                        <small>Valor do Aluguel</small>
                      </p>
                      <FormItemCt
                        isRequired={fieldIsRequired('valorAluguel')}
                        className="my-2"
                        name="valorAluguel">
                        <Input
                          placeholder=" Valor do Aluguel"
                          onChange={(e) =>
                            handleMaskCurrency(e, 'valorAluguel')
                          }
                          prefix="R$"
                        />
                      </FormItemCt>
                    </ColCt>
                  )}
                  <ColCt
                    hide={fieldIsHidden('condominioId')}
                    xs="12"
                    md="3"
                    className="my-2">
                    <p
                      className="heading-small text-muted mb-2"
                      style={{ height: '22px' }}>
                      <small style={{ display: 'flex', alignItems: 'baseline' }}>
                        Valor do Condomínio (isendo?
                        <FormItemCt
                          isRequired={fieldIsRequired('condominioIsento')}
                          valuePropName="checked"
                          name="condominioIsento">
                          <Checkbox
                            onChange={() => {
                              form.validateFields();
                            }}
                            style={{ padding: '0 2px' }}
                          />
                        </FormItemCt>
                        )
                      </small>
                    </p>
                    <FormItemCt
                      rules={[
                        // eslint-disable-next-line no-empty-pattern
                        ({ }) => ({
                          validator(rule, value) {
                            const isentValue =
                              form.getFieldValue('condominioIsento');
                            const condValue =
                              form.getFieldValue('condominioId');
                            if (!value && !isentValue && condValue) {
                              return Promise.reject('Favor informar');
                            }

                            return Promise.resolve();
                          },
                        }),
                      ]}
                      className="my-2"
                      name="valorCondominio">
                      <Input
                        placeholder=" Valor do Condomínio"
                        onChange={(e) =>
                          handleMaskCurrency(e, 'valorCondominio')
                        }
                        prefix="R$"
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
                <Row>
                  <ColCt xs="12" md="12" className="my-2">
                    <p className="heading-small text-muted mb-2">
                      <small>Condições de Pagamento</small>
                    </p>
                    <FormItemCt
                      isRequired={fieldIsRequired('condicoesPagamento')}
                      className="my-2"
                      name="condicoesPagamento">
                      <TextArea
                        placeholder=" Condições de Pagamento"
                        autoSize={{ minRows: 3, maxRows: 5 }}
                        maxLength={2000}
                        showCount
                      />
                    </FormItemCt>
                  </ColCt>
                </Row>
              </Form>
              <Row className="my-4">
                <ColCt xs="12" md="3">
                  <Button
                    disabled={loadingEndereco}
                    onClick={() => onSaveCadastroGeral()}
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    color="violet"
                    isloading={loading?.toString()}
                    loadtext={
                      modo === MODO.CADASTRAR
                        ? 'Cadastrando...'
                        : 'Atualizando...'
                    }>
                    <Icon name="check" />
                    {modo === MODO.CADASTRAR ? 'Cadastrar' : 'Atualizar'}
                  </Button>
                </ColCt>
                <ColCt xs="12" md="3">
                  <Button
                    icon
                    labelPosition="left"
                    size="medium"
                    fluid
                    className="mt-3"
                    type="button"
                    onClick={() => voltar()}>
                    <Icon name="undo" />
                    Voltar
                  </Button>
                </ColCt>
              </Row>
            </>
          )}
        </ColCt>
      </Row>

      <ModalConfirm
        isDelete={false}
        icon="check circle"
        open={openModalConfirm}
        close={() => handleAbas(showTabs)}
        message={
          modo === MODO.CADASTRAR
            ? 'Cadastrado com sucesso'
            : 'Alterações salvas'
        }
      />

      <ModalCondominio
        open={modalCondominioOpen}
        close={() => {
          setModalCondominioOpen(false);
          onSearchCondominio();
        }}
      />

      <ModalFinalidade
        open={modalFinalidadeOpen}
        close={() => {
          setModalFinalidadeOpen(false);
          onSearchFinalidade();
        }}
      />

      <ModalTipoImovel
        finalidadeId={selectedFinalidadeId}
        open={modalTipoImovelOpen}
        close={() => {
          setModalTipoImovelOpen(false);
          onSearchTipoImovel();
        }}
      />

      <ModalLogradouro
        open={modalLogradouroOpen}
        close={() => {
          setModalLogradouroOpen(false);
          onSearchLogradouro();
        }}
      />
    </div>
  );
}

export default CadastroGeral;
