
import React, { useCallback, useEffect, useState } from 'react';
import { Select, Form } from 'antd';
import api from '~/services/api';
import URL from '~/infra/urls';
import SkeletonInput from 'antd/lib/skeleton/Input';




const { Option } = Select;

const SelectCampanhaRdStation = ({ finalValues, setFinalValues }) => {

    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const empresaId = localStorage.getItem('icaseEmpresaId');

    const onLoad = useCallback(async () => {
        try {
            await api
                .get(`${URL.DROPDOWN.RD_CAMPANHAS_DROPDOWN(empresaId)}`)
                .then((res) => {
                    setData(res.data || []);
                });
        } catch (e) {
            setData([]);
        } finally {
            setIsLoading(false);
        }
    }, [empresaId]);

    useEffect(() => {
        onLoad();
    }, [onLoad]);

    return isLoading ?
        <SkeletonInput active /> :
        <Form.Item className="mb-2" name="campanhasRdId">
            <Select
                showSearch
                filterOption={(input, option) =>
                    option?.children
                        ?.toLowerCase()
                        ?.normalize('NFD')
                        ?.replace(/[\u0300-\u036f]/g, '')
                        ?.indexOf(
                            input
                                ?.toLowerCase()
                                ?.normalize('NFD')
                                ?.replace(/[\u0300-\u036f]/g, ''),
                        ) >= 0
                }
                placeholder=" Campanhas RD Station"
                mode='multiple'
                onChange={(value, obj) => {
                    const campanhasDesc = obj?.map(c => c.children);
                    setFinalValues({ ...finalValues, ...{ campanhasRdDesc: campanhasDesc } });
                }}
                allowClear>
                {data.map((item) => (
                    <Option key={item.id}
                    >
                        {item.name}
                    </Option>
                ))}
            </Select>
        </Form.Item>;
}

export default SelectCampanhaRdStation;