/* eslint-disable no-unused-vars */
import React, {Fragment, useEffect, useState, useRef} from 'react';
import {Card, CardHeader, CardBody, Row, Col, CardFooter} from 'reactstrap';
import {Form, Select, Input, Table, Tooltip} from 'antd';

import useLeadOfertaAtiva from '~/actions/OfertaAtiva/useLeadOfertaAtiva';
import ModalConfirm from '~/components/ModalConfirm';

import Page from '~/components/Page';
import HeaderFiltros from '~/components/HeaderFiltro';
import history from '~/services/history';
import EmptyBox from '~/components/EmptyBox';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import Button from '~/shared/Button';
import {Icon} from 'semantic-ui-react';
import defaultPagination from '~/infra/resources/pattern/defaultPagination';
import pt from '~/infra/resources/strings/pt';
import url from '~/infra/urls';
import api from '~/services/api';
import Notify from '~/shared/Notify';
import formatTelefone from '~/shared/formatTelefone';

import 'moment/locale/pt-br';
import moment from 'moment';
import useCorretorDropdown from '~/actions/DropDown/useCorretorDropdown';
moment.locale('pt-BR');

const {Option} = Select;

export function navToNovo() {
  history.push(`/app/oferta`);
}

function ConsultaOfertaAtiva(props) {
  const [current, setCurrent] = useState(1);
  const [pageSize] = useState(10);
  const [loadingDel, setLoadingDel] = useState(false);

  const [status, setStatus] = useState();
  const [nome, setNome] = useState();
  const [corretorId, setCorretorId] = useState();
  const [ofertaId, setOfertaId] = useState();
  const ref = useRef();
  const [actions] = useState([]);
  const {data: dataCorretores} = useCorretorDropdown();
  const {isLoading, onLoad, data, quantidade} = useLeadOfertaAtiva(
    current,
    pageSize,
    nome,
    corretorId,
    status,
    ofertaId,
  );
  const [loadTable, setloadTable] = useState(false);
  const [leadId, setLeadId] = useState(0);
  const [openModalDeleteConfirm, setOpenModalDeleteConfirm] = useState(false);
  const [identificador, setIdentificador] = useState();
  const isEmpty = !isLoading && data.length <= 0 && !loadTable;

  useEffect(() => {
    if (props.match.params) {
      const {row: rowData, identificador} = props.location.state;
      setOfertaId(rowData.id);
      setIdentificador(identificador);
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params]);

  function voltar() {
    if (props?.onVoltar) {
      props.onVoltar();
    } else if (identificador) {
      history.push(`/app/oferta-ativa/${identificador}`);
    } else {
      history.push('/app/oferta-ativa');
    }
  }

  function onFiltros() {
    setloadTable(true);
    const {status, nome, corretorId} = ref.current.getFieldsValue();
    setStatus(status);
    setNome(nome);
    setCorretorId(corretorId);

    setTimeout(() => {
      setloadTable(false);
    }, 1200);
  }

  async function changePage(page) {
    setloadTable(true);
    setCurrent(page);

    setTimeout(() => {
      setloadTable(false);
    }, 1100);
  }

  async function handleConfirmDelete() {
    if (leadId) {
      setLoadingDel(true);

      await api
        .delete(`${url.OFERTA_ATIVA.DELETAR_LEAD()}/${leadId}`)
        .then(() => {
          setLoadingDel(false);
          onLoad();
          setOpenModalDeleteConfirm(false);
          Notify('success', '', 'Registro excluído com sucesso!');
        })
        .catch(() => {
          setLoadingDel(false);
          setOpenModalDeleteConfirm(false);
          Notify(
            'error',
            pt.comum.atencao,
            'Ocorreu um erro ao excluir o registro!',
          );
        });
    }
  }

  function handleCloseModalDelete() {
    setOpenModalDeleteConfirm(false);
  }

  function handleModalConfirm(id) {
    setOpenModalDeleteConfirm(true);
    setLeadId(id);
  }

  function getStatusName(status) {
    switch (status) {
      case 1:
        return 'Aguardando';
      case 2:
        return 'Em atendimento';
      case 3:
        return 'Recusado';
      case 4:
        return 'Falha no contato';
      case 5:
        return 'Contato inicial';
      case 6:
        return 'Telefone Inexistente';
      case 7:
        return 'Reservado';
      default:
        return '';
    }
  }

  const incluir = !!actions.find(
    (create) => create.id === 'cde0a657-de71-4516-a90c-a2f4f21f1a35',
  );

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'nomeCompleto',
      key: 'nomeCompleto',
    },
    {
      title: 'Status',
      render: (row) => <div key={row.index}>{getStatusName(row.status)}</div>,
    },
    {
      title: 'Telefone',
      render: (row) => (
        <div key={row.index}>
          {formatTelefone(
            row?.telefone1 ||
              row?.telefone2 ||
              row?.telefone3 ||
              row?.telefone4,
          )}
        </div>
      ),
    },
    {
      title: 'Corretor',
      render: (row) => {
        const ultimoCorretorAlteracao = row?.usuarioAlteracao;
        if (ultimoCorretorAlteracao) {
          return (
            <Tooltip
              key={row.index}
              title={
                <div>
                  <p>Corretor: {ultimoCorretorAlteracao.nome}</p>
                  <p>
                    Situação:{' '}
                    {ultimoCorretorAlteracao.excluido ? 'Inativo' : 'Ativo'}
                  </p>
                </div>
              }>
              <span
                style={{
                  color: ultimoCorretorAlteracao.excluido ? '#a7a3a3' : '#000',
                  cursor: 'pointer',
                }}>
                {ultimoCorretorAlteracao.nome}
              </span>
            </Tooltip>
          );
        }

        return (
          <Tooltip
            key={row.index}
            title={<p>Não há corretor vinculado a esta oferta</p>}>
            <span style={{color: '#a7a3a3', cursor: 'pointer'}}>{'-'}</span>
          </Tooltip>
        );
      },
    },
    {
      title: 'Excluir',
      key: '',
      fixed: 'center',
      width: 80,
      render: (row) => (
        <Tooltip title="Excluir Registro">
          <div className="p-1" key={row.index}>
            <i
              onClick={() => handleModalConfirm(row.id)}
              className="ri-delete-bin-5-fill linkTable"
              style={{
                fontSize: '26px',
                marginRight: '10px',
                color: '#E01D20',
              }}></i>
          </div>
        </Tooltip>
      ),
    },
  ];

  return (
    <Fragment>
      <Page loadPage={isLoading}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardHeader>
                <h5 className="title">Oferta ativa: Lista de Leads</h5>
                <p className="category">Manutenção de leads da oferta ativa</p>
                {incluir === true ? (
                  <HeaderFiltros
                    description="Nova Oferta"
                    navToNovo={() => navToNovo()}
                  />
                ) : null}
              </CardHeader>
              <CardBody>
                <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form initialValues={{}} ref={ref} scrollToFirstError>
                        <Row>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="nome">
                              <Input placeholder=" Nome" />
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="4">
                            <Form.Item className="my-2" name="corretorId">
                              <Select allowClear placeholder=" Corretores">
                                {dataCorretores.map((item) => (
                                  <Option
                                    key={item.id}
                                    disabled={item.isSeparator}>
                                    {item?.nome?.toString()}
                                  </Option>
                                ))}
                              </Select>
                            </Form.Item>
                          </Col>
                          <Col xs="12" md="3">
                            <Form.Item className="my-2" name="status">
                              <Select allowClear placeholder=" Status">
                                <Option value={1}>Aguardando</Option>
                                <Option value={2}>Em atendimento</Option>
                                <Option value={3}>Recusado</Option>
                                <Option value={4}>Falha no contato</Option>
                                <Option value={5}>Contato inicial</Option>
                                <Option value={7}>Reservado</Option>
                              </Select>
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs="12" md="2">
                            <Button
                              className="my-2"
                              size="tiny"
                              icon
                              labelPosition="left"
                              fluid
                              onClick={() => onFiltros()}>
                              <Icon name="search" />
                              Filtrar
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Col>
                </Row>
                {loadTable && (
                  <Fragment>
                    <Row className="mt-4">
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                      <Col xs="12" md="3">
                        <SkeletonTableBasic cardShadowless lineNumber={1} />
                      </Col>
                    </Row>
                  </Fragment>
                )}
                {!isEmpty && !isLoading && !loadTable && (
                  <Fragment>
                    <div className="my-2">
                      <Table
                        rowKey={(row) => row.id}
                        columns={columns}
                        dataSource={data}
                        loading={loadTable}
                        pagination={defaultPagination(
                          current,
                          quantidade,
                          changePage,
                        )}
                      />
                    </div>
                  </Fragment>
                )}
                {isEmpty && (
                  <EmptyBox message="Verifique se há filtros aplicados limitando os resultados ou realmente não possui nenhum registro nesta página" />
                )}
              </CardBody>
              <CardFooter>
                <Row>
                  <Col xs="12" md="4">
                    <Button
                      icon
                      labelPosition="left"
                      size="medium"
                      fluid
                      className="mt-3"
                      onClick={voltar}>
                      <Icon name="reply" />
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>

            <ModalConfirm
              isDelete
              icon="warning sign"
              loading={loadingDel}
              open={openModalDeleteConfirm}
              confirm={handleConfirmDelete}
              close={handleCloseModalDelete}
              message="Deseja realmente excluir esse registro?"
            />
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ConsultaOfertaAtiva;
