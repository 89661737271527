import React, { useState, useEffect, Fragment } from 'react';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { Form, Input, Select, Steps, Switch } from 'antd';

import { Icon } from 'semantic-ui-react';

import Page from '~/components/Page';
import ButtonShared from '~/shared/Button';
import Notify from '~/shared/Notify';
import url from '~/infra/urls';

import api from '~/services/api';
import pt from '~/infra/resources/strings/pt';

import useTipoMidiaDropdown from '~/actions/DropDown/useTipoMidiaDropdown';
import ResumoDistribuicao from './components/resumo';
import SkeletonTableBasic from '~/components/SkeletonTableBasic';
import MapeamentoCampos from './components/mapeamentoCampos';
import FormaDistribuicao from './components/formaDistribuicao';
import FormaRedistribuicao from './components/formaRedistribuicao';
import FacebookParam from './components/facebookParam';
import useRoles from '~/hooks/use-roles';
import SelectCampanhaRdStation from './components/selectCampanhaRdStation';



const { Option } = Select;

const MODO = {
  CADASTRAR: 'cadastrar',
  EDITAR: 'editar',
};

function ManutDistribuicaoLeads({ open, modo, id, onVoltar }) {
  const [isLoading, setIsLoading] = useState(false);
  const [tipoDistribuicaoId, setTipoDistribuicaoId] = useState(null);
  const [tipoRedistribuicaoId, setTipoRedistribuicaoId] = useState(null);
  const [tiposMidiaId, setTiposMidiaId] = useState(null);
  // const [campanhasRdId, setCampanhasRdId] = useState(null);
  const [fazRedistribuicao, setFazRedistribuicao] = useState(false);
  const [paginaFacebookId, setPaginaFacebookId] = useState(null);
  const { data: dataTiposMidia } = useTipoMidiaDropdown(null, null, true);
  const { isGerente, isCorretor } = useRoles();

  const [finalValues, setFinalValues] = useState({});
  const [usuariosOptions, setUsuariosOptions] = useState([]);
  const [usuariosRedOptions, setUsuariosRedOptions] = useState([]);


  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);


  const next = () => {
    form.validateFields().then(async (dados) => {
      setCurrent(current + 1);

      let newValues = { ...finalValues };

      if (tiposMidiaId?.length > 1 || tiposMidiaId[0] !== '107') {
        newValues.facebookParam = null;
        newValues.mapeamentoCampos = null;
        newValues.paginaFacebookDesc = null;
        newValues.formulariosFacebookDesc = null;
        newValues.todosFormsFacebook = false;
        newValues.formFacebookDesconsiderarDesc = null;
      }

      setFinalValues({ ...newValues, ...dados })
    });
  };
  const prev = () => {
    setCurrent(current - 1);
  };


  const contentStyle = {
    marginTop: 16,
  };


  const steps = [
    modo !== 'resumo' ? {
      title: 'ENTRADA',
      content: <Row className="p-4">
        <Col xs="12" md="12">
          <p className="heading-small text-muted mb-0">
            <small>Nome</small>
          </p>
          <Form.Item
            className="mb-2"
            name="nome"
            rules={[
              {
                required: true,
                message: 'Defina um nome para a regra',
              },
            ]}>
            <Input placeholder=" Defina um nome para a regra" />
          </Form.Item>
        </Col>
        <Col xs="12" md="12">
          <p className="heading-small text-muted mt-2 mb-0">
            <small>Faz redistribuição de Leads?</small>
          </p>
          <Form.Item className="mb-2" name="fazRedistribuicao">
            <Switch
              defaultChecked={fazRedistribuicao}
              onChange={(value) => setFazRedistribuicao(value)}
              checkedChildren="Sim"
              unCheckedChildren="Não"
            />
          </Form.Item>
        </Col>
        <Col xs="12" md="12">
          <p className="heading-small text-muted mt-2 mb-0">
            <small>Tipos de Mídia</small>
          </p>
          <Form.Item className="mb-2" name="tiposMidiaId"
            rules={[
              {
                required: true,
                message: 'Informe Tipos de Mídia',
              },
            ]}>
            <Select
              showSearch
              filterOption={(input, option) =>
                option?.children
                  ?.toLowerCase()
                  ?.normalize('NFD')
                  ?.replace(/[\u0300-\u036f]/g, '')
                  ?.indexOf(
                    input
                      ?.toLowerCase()
                      ?.normalize('NFD')
                      ?.replace(/[\u0300-\u036f]/g, ''),
                  ) >= 0
              }
              placeholder=" Tipos de Mídia"
              mode='multiple'
              onChange={(value, obj) => {
                const tiposDesc = obj?.map(c => c.children);
                setFinalValues({ ...finalValues, ...{ tiposMidiaDesc: tiposDesc } });
                setTiposMidiaId(value);
              }}
              allowClear>
              {dataTiposMidia.filter(t => t.id === 107 || !(isCorretor || isGerente)).map((item) => (
                <Option key={item.id}
                >
                  {item.descricao}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {tiposMidiaId?.length === 1 && tiposMidiaId[0] === '107'
          && <FacebookParam
            finalValues={finalValues}
            paginaFacebookId={paginaFacebookId}
            setFinalValues={setFinalValues}
            setPaginaFacebookId={setPaginaFacebookId}
            form={form}
            distribuicaoId={id}
          />}
        {tiposMidiaId?.length === 1 && tiposMidiaId[0] === '134'
          && <Col xs="12" md="12">
            <p className="heading-small text-muted mt-2 mb-0">
              <small>Campanhas RD Station</small>
            </p>
            <SelectCampanhaRdStation
              //     setCampanhasRdId={setCampanhasRdId}
              finalValues={finalValues}
              setFinalValues={setFinalValues} />
          </Col>}
      </Row>,
    } : null,
    modo !== 'resumo' && tiposMidiaId?.length === 1 && tiposMidiaId[0] === '107' ? {
      title: 'MAPEAMENTO',
      content: <MapeamentoCampos finalValues={finalValues} />
    } : null,
    modo !== 'resumo' ? {
      title: 'DISTRIBUIÇÃO',
      content: <FormaDistribuicao
        isLoading={isLoading}
        setFinalValues={setFinalValues}
        finalValues={finalValues}
        tipoDistribuicaoId={tipoDistribuicaoId}
        setTipoDistribuicaoId={setTipoDistribuicaoId}
        usuariosOptions={usuariosOptions}
        setUsuariosOptions={setUsuariosOptions} />,
    } : null,
    modo !== 'resumo' && !!fazRedistribuicao ? {
      title: 'REDISTRIBUIÇÃO',
      content: <FormaRedistribuicao
        isLoading={isLoading}
        setFinalValues={setFinalValues}
        finalValues={finalValues}
        tipoRedistribuicaoId={tipoRedistribuicaoId}
        setTipoRedistribuicaoId={setTipoRedistribuicaoId}
        usuariosRedOptions={usuariosRedOptions}
        setUsuariosRedOptions={setUsuariosRedOptions} />,
    } : null,
    {
      title: 'RESUMO',
      content: <ResumoDistribuicao values={finalValues} fazRedistribuicao={fazRedistribuicao} />,
    },
  ].filter(i => !!i);

  useEffect(() => {
    if (open) {
      if (id) {
        setIsLoading(true);

        async function fetchData() {
          await api.get(url.DISTRIBUICAO_LEAD.GET_BY_ID(id))
            .then((res) => {

              const {
                nome,
                tiposDeMidia,
                tipoDistribuicao,
                tipoDistribuicaoDesc,
                equipes,
                empresas,
                usuarios,
                tiposUsuarios,
                facebookParam,
                mapeamentoCampos,
                redistribuicao,
                campanhasRdStation,
              } = res?.data?.data;

              setTipoDistribuicaoId(tipoDistribuicao?.toString());
              setTiposMidiaId(tiposDeMidia?.map(i => i.id.toString()));
              //       setCampanhasRdId(campanhasRdStation?.map(i => i.id.toString()));

              if (!!usuarios && usuarios?.length > 0)
                setUsuariosOptions(usuariosOptions.concat(usuarios?.map((usr) => ({
                  id: usr?.id,
                  nome: usr?.descricao,
                }))));

              if (!!redistribuicao) {
                setFazRedistribuicao(true);
                setTipoRedistribuicaoId(redistribuicao?.tipoRedistribuicao?.toString());

                if (!!redistribuicao?.usuarios && redistribuicao?.usuarios?.length > 0)
                  setUsuariosRedOptions(usuariosRedOptions.concat(redistribuicao.usuarios.map((usr) => ({
                    id: usr?.id,
                    nome: usr?.descricao,
                  }))));
              }

              if (!!facebookParam?.pagina)
                setPaginaFacebookId(facebookParam.pagina);


              setFinalValues({
                ...finalValues,
                ...{
                  nome,
                  tipoDistribuicaoId: tipoDistribuicao?.toString(),
                  tipoDistribuicaoDesc,
                  tipoRedistribuicaoId: redistribuicao?.tipoRedistribuicao?.toString(),
                  tipoRedistribuicaoDesc: redistribuicao?.tipoRedistribuicaoDesc?.toString(),
                  tiposMidiaDesc: tiposDeMidia?.map(i => i.descricao),
                  campanhasRdDesc: campanhasRdStation?.map(i => i.name),
                  equipesDesc: equipes?.map(i => i.descricao),
                  empresasId: empresas?.map(i => i.id),
                  empresasDesc: empresas?.map(i => i.descricao),
                  usuariosDesc: usuarios?.map(i => i.descricao),
                  tiposUsuarioDesc: tiposUsuarios?.map(i => i.descricao),

                  prazoRedistribuicao: redistribuicao?.prazo,
                  qtdRedistribuicao: redistribuicao?.quantidade,
                  empresasRedistribuicaoId: redistribuicao?.empresas?.map(i => i.id),
                  equipesRedistribuicaoDesc: redistribuicao?.equipes?.map(i => i.descricao),
                  empresasRedistribuicaoDesc: redistribuicao?.empresas?.map(i => i.descricao),
                  usuariosRedistribuicaoDesc: redistribuicao?.usuarios?.map(i => i.descricao),
                  tiposUsuarioRedistribuicaoDesc: redistribuicao?.tiposUsuarios?.map(i => i.descricao),

                  paginaFacebookId: facebookParam?.pagina,
                  paginaFacebookDesc: facebookParam?.paginaDesc,
                  campanhaFacebookId: facebookParam?.campanha,
                  formulariosFacebookId: facebookParam?.formularios?.map(i => i.formId),
                  formFacebookDesconsiderarId: facebookParam?.formulariosDesconsiderar?.map(i => i.formId),
                  formulariosFacebookDesc: facebookParam?.formularios,
                  formFacebookDesconsiderarDesc: facebookParam?.formulariosDesconsiderar,
                  todosFormsFacebook: facebookParam?.todosForms,
                  mapeamentoCampos
                }
              });

              form.setFieldsValue(
                {
                  nome: nome,
                  tiposMidiaId: tiposDeMidia?.map(i => i.id.toString()),
                  campanhasRdId: campanhasRdStation?.map(i => i.id.toString()),
                  tipoDistribuicaoId: tipoDistribuicao?.toString(),
                  tipoRedistribuicaoId: redistribuicao?.tipoRedistribuicao?.toString(),
                  equipesId: equipes?.map(i => i.id),
                  usuariosId: usuarios?.map(i => i.id),
                  tiposUsuarioId: tiposUsuarios?.map(i => i.id),
                  empresasId: empresas?.map(i => i.id),

                  prazoRedistribuicao: redistribuicao?.prazo,
                  qtdRedistribuicao: redistribuicao?.quantidade,
                  equipesRedistribuicaoId: redistribuicao?.equipes?.map(i => i.id),
                  usuariosRedistribuicaoId: redistribuicao?.usuarios?.map(i => i.id),
                  tiposUsuarioRedistribuicaoId: redistribuicao?.tiposUsuarios?.map(i => i.id),
                  empresasRedistribuicaoId: redistribuicao?.empresas?.map(i => i.id),

                  paginaFacebookId: facebookParam?.pagina,
                  paginaFacebookDesc: facebookParam?.paginaDesc,
                  campanhaFacebookId: facebookParam?.campanha,
                  formulariosFacebookId: facebookParam?.formularios?.map(i => i.formId),
                  formFacebookDesconsiderarId: facebookParam?.formulariosDesconsiderar?.map(i => i.formId),
                  todosFormsFacebook: facebookParam?.todosForms,
                  mapeamentoCampos
                }
              );

            })
            .catch((err) => {
              setIsLoading(false);

              if (modo === 'resumo') {
                onVoltar();
              }

              const { error } = err?.response?.data?.data;
              if (error) {
                for (const item in error) {
                  Notify('error', pt.comum.atencao, error[item]);
                }
              }
            }).finally(() => {
              setIsLoading(false);
            });
        }

        fetchData();
      } else {
        const latitudeCompanyData = JSON.parse(localStorage.getItem('latitudeCompanyData'));
        const listEmpresas = JSON.parse(localStorage.getItem('icaseEmpresas')) || [];

        const isMatriz = !!!latitudeCompanyData.matrizId;

        if ((isMatriz || listEmpresas.length > 1) && latitudeCompanyData.id) {
          setFinalValues({
            ...finalValues,
            ...{
              empresasId: [latitudeCompanyData.id],
              empresasRedistribuicaoId: [latitudeCompanyData.id],
            }
          });
          form.setFieldsValue(
            {
              empresasId: [latitudeCompanyData.id],
              empresasRedistribuicaoId: [latitudeCompanyData.id],
            }
          );
        }

        setIsLoading(false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);


  function onSave() {
    setIsLoading(true);
    form.validateFields().then(async (dados) => {

      setIsLoading(true);

      const {
        tipoDistribuicaoId,
        tipoRedistribuicaoId,
        nome,
        tiposMidiaId,
        campanhasRdId,
        equipesId,
        usuariosId,
        tiposUsuarioId,
        empresasId,
        usuariosRedistribuicaoId,
        equipesRedistribuicaoId,
        tiposUsuarioRedistribuicaoId,
        empresasRedistribuicaoId,
        prazoRedistribuicao,
        qtdRedistribuicao,
        paginaFacebookId,
        paginaFacebookDesc,
        accessTokenPageLongTime,
        campanhaFacebookId,
        formulariosFacebookDesc,
        formFacebookDesconsiderarDesc,
        mapeamentoCampos,
        todosFormsFacebook } = finalValues;

      let payload = {
        empresaId: localStorage.getItem('icaseEmpresaId'),
        nome,
        tipoDistribuicao: tipoDistribuicaoId,
        tiposDeMidia: tiposMidiaId,
        campanhasRdStation: (tiposMidiaId?.length === 1 && tiposMidiaId[0] === '134') ? campanhasRdId : null,
        usuarios: usuariosId,
        equipes: equipesId,
        tiposUsuarios: tiposUsuarioId,
        empresas: empresasId,
        redistribuicao: !!tipoRedistribuicaoId ? {
          prazo: prazoRedistribuicao,
          quantidade: qtdRedistribuicao,
          tipoRedistribuicao: tipoRedistribuicaoId,
          usuarios: usuariosRedistribuicaoId,
          equipes: equipesRedistribuicaoId,
          tiposUsuarios: tiposUsuarioRedistribuicaoId,
          empresas: empresasRedistribuicaoId,
        } : null,
        facebookParam: (tiposMidiaId?.length === 1 && tiposMidiaId[0] === '107') && !!paginaFacebookId ? {
          pagina: paginaFacebookId,
          paginaDesc: paginaFacebookDesc,
          campanha: campanhaFacebookId,
          formularios: formulariosFacebookDesc && formulariosFacebookDesc.length > 0 ? formulariosFacebookDesc : null,
          formulariosDesconsiderar: formFacebookDesconsiderarDesc && formFacebookDesconsiderarDesc.length > 0 ? formFacebookDesconsiderarDesc : null,
          accessTokenPage: !!accessTokenPageLongTime ? accessTokenPageLongTime : null,
          todosForms: todosFormsFacebook,
        } : null,
        mapeamentoCampos: (tiposMidiaId?.length === 1 && tiposMidiaId[0] === '107') ? mapeamentoCampos : null,
        fazRedistribuicao
      };

      if (modo === MODO.CADASTRAR) {
        await api.post(url.DISTRIBUICAO_LEAD.CADASTRAR(), payload)
          .then((res) => {
            setIsLoading(false);

            Notify('success', '', pt.geral.cadastro_com_sucesso);

            onVoltar();
          })
          .catch((err) => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      } else if (modo === MODO.EDITAR) {
        payload = { ...payload, id: id };

        await api
          .put(url.DISTRIBUICAO_LEAD.EDITAR(), payload)
          .then(() => {
            setIsLoading(false);
            Notify('success', '', pt.geral.atualizado_com_sucesso);
            onVoltar();
          })
          .catch((err) => {
            setIsLoading(false);
            const { error } = err.response.data;
            if (error) {
              for (const item in error) {
                Notify('error', pt.comum.atencao, error[item]);
              }
            }
          });
      }
    })
      .catch(() => {
        setIsLoading(false);
        window.scrollTo(1, 1);
      });
  }

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));


  return (
    <Fragment>
      <Page loadPage={false}>
        <Row>
          <Col xs="12">
            <Card className="p-2">
              <CardBody>
                {isLoading ? <SkeletonTableBasic /> : <Row>
                  <Col xs="12">
                    <div className="my-2 p-2">
                      <Form
                        form={form}
                        scrollToFirstError>
                        <Steps type="inline" current={current} items={items} />
                        <div style={contentStyle}>{steps[current].content}</div>
                        {modo !== 'resumo' && <div
                          style={{
                            marginTop: 24,
                          }}
                        >
                          <Row>
                            <Col xs="12">
                              <Row>
                                {current < steps.length - 1 && (
                                  <Col xs="12" md="3">
                                    <ButtonShared
                                      onClick={next}
                                      icon
                                      labelPosition="left"
                                      size="medium"
                                      fluid
                                      color="violet">
                                      <Icon name="angle double right" />
                                      Avançar
                                    </ButtonShared>
                                  </Col>
                                )}
                                {current === steps.length - 1 && (

                                  <Col xs="12" md="3">
                                    <ButtonShared
                                      onClick={onSave}
                                      icon
                                      labelPosition="left"
                                      size="medium"
                                      fluid
                                      color="violet"
                                      isloading={isLoading.toString()}
                                      loadtext={
                                        modo === MODO.CADASTRAR
                                          ? 'Cadastrando...'
                                          : 'Atualizando...'
                                      }>
                                      <Icon name="check" />
                                      {modo === MODO.CADASTRAR
                                        ? 'Cadastrar'
                                        : 'Atualizar'}
                                    </ButtonShared>
                                  </Col>

                                )}
                                {current > 0 && (
                                  <Col xs="12" md="3">
                                    <ButtonShared
                                      icon
                                      labelPosition="left"
                                      size="medium"
                                      fluid
                                      onClick={() => prev()}>
                                      <Icon name="reply" />
                                      Voltar
                                    </ButtonShared>
                                  </Col>
                                )}
                                <Col xs="12" md="3">
                                  <ButtonShared
                                    icon
                                    labelPosition="left"
                                    size="medium"
                                    color="red"
                                    fluid
                                    onClick={onVoltar}>
                                    Cancelar
                                  </ButtonShared>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>}
                      </Form>
                    </div>
                  </Col>
                </Row>}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Page>
    </Fragment>
  );
}

export default ManutDistribuicaoLeads;
