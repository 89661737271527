import React from 'react';
import {TipoUsuario, tipoUsuarioOrdem} from './constants';

export const filterOption = (input, option) => {
  if (Array.isArray(option.options)) {
    return false;
  }

  return (
    option.children
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .indexOf(
        input
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ) >= 0
  );
};

export const mapOptionsGroup = (usuarios) => {
  const usuariosPorTipo = usuarios.reduce((acc, item) => {
    const tipo =
      typeof item.tipoUsuario === 'string'
        ? item.tipoUsuario.toUpperCase()
        : 'nada';
    if (!TipoUsuario[tipo]) {
      return acc;
    }
    if (!acc[tipo]) {
      acc[tipo] = [];
    }

    acc[tipo].push(item);
    return acc;
  }, {});

  return Object.entries(usuariosPorTipo)
    .map(([key, value]) => ({
      key,
      label: <strong>{TipoUsuario[key]}</strong>,
      title: TipoUsuario[key],
      options: value.map((item) => ({
        id: item.id,
        label: item.nome,
        key,
      })),
    }))
    .sort(
      (a, b) =>
        tipoUsuarioOrdem.indexOf(a.title) - tipoUsuarioOrdem.indexOf(b.title),
    );
};
