import React, {useState, Fragment} from 'react';
import {Card, CardBody} from 'reactstrap';
import {Form, Steps, Row, Col, Select} from 'antd';
import {Modal} from 'semantic-ui-react';
import {decisaoAtendimentosOptions, decisaoImoveisOptions} from './constants';

import Page from '~/components/Page';
import ButtonShared from '~/shared/Button';

import {Icon} from 'semantic-ui-react';
import SelectCorretoresOrGrupoCorretoresInput from '~/components/SelectCorretoresOrGrupoCorretoresInput';
import useInativarUsuario from '~/actions/Usuario/useInativarUsuario';
import ProgressBarInativarUsuario from './ProgressBar';
import TagMembros from './TagMembros';

const {Option} = Select;

function ModalInativarUsuario({open, close, usuarioId, onUpdate}) {
  const [finalValues, setFinalValues] = useState({});

  const [form] = Form.useForm();
  const [current, setCurrent] = useState(0);
  const [decisaoAtendimento, setDecisaoAtendimento] = useState(
    'nada-apenas-inativar',
  );
  const [membrosAtendimentos, setMembrosAtendimentos] = useState({});
  const [decisaoImoveis, setDecisaoImoveis] = useState('nada-apenas-inativar');
  const [membrosImoveis, setMembrosImoveis] = useState({});
  const {isLoading, inativarUsuario} = useInativarUsuario();

  function agruparIdsPorTipo(dados) {
    return dados.reduce((acumulador, item) => {
      const tipoCamelCase = item.options.type.replace(
        /-([a-z])/g,
        (match, letter) => letter.toUpperCase(),
      );

      if (!acumulador[tipoCamelCase]) {
        acumulador[tipoCamelCase] = [];
      }

      acumulador[tipoCamelCase].push(item.key);

      return acumulador;
    }, {});
  }

  const nextStep = () => {
    form.validateFields().then(async (values) => {
      setCurrent(current + 1);
      let newValues = {...finalValues};
      setFinalValues({...newValues, ...values});
    });
  };
  const previousStep = () => {
    setCurrent(current - 1);
  };

  const clearState = () => {
    setCurrent(0);
    setDecisaoAtendimento('nada-apenas-inativar');
    setDecisaoImoveis('nada-apenas-inativar');
    setMembrosAtendimentos({raw: []});
    setMembrosImoveis({raw: []});
    setFinalValues({});
    window.location.reload();
  };
  const onSave = () => {
    console.log(membrosImoveis);
    inativarUsuario(
      usuarioId,
      decisaoAtendimento,
      membrosAtendimentos?.corretor ?? [],
      membrosAtendimentos?.grupoCorretores ?? [],
      decisaoImoveis,
      membrosImoveis?.corretor ?? [],
      membrosImoveis?.grupoCorretores ?? [],
    ).then(() => {
      close();
      clearState();
      onUpdate();
    });
  };

  const steps = [
    {
      title: 'Atendimentos Abertos',
      content: (
        <Col xs="12" className="w-100">
          <Col className="mb-2">
            <p className="heading-small text-muted mb-2">
              <small>O que fazer com os atendimentos deste usuário?</small>
            </p>
            <Select
              key="atendimentos"
              className="w-100"
              defaultValue={decisaoAtendimento}
              onChange={(value) => setDecisaoAtendimento(value)}>
              {decisaoAtendimentosOptions.map((option) => (
                <Option key={option.key}>{option.label}</Option>
              ))}
            </Select>
          </Col>
          <Row hidden={decisaoAtendimento !== 'distribuir-para-membros'}>
            <Form.Item name="membrosSelecionadosAtendimentos" className="w-100">
              <SelectCorretoresOrGrupoCorretoresInput
                enableHelperTooltip
                disableSelfSelect
                enableTagRender
                defaultValue={membrosAtendimentos?.raw ?? []}
                placeholder={
                  membrosAtendimentos?.raw
                    ? 'Carregando membros selecionados...'
                    : 'Selecione membros...'
                }
                label={'Distribuir entre'}
                onChange={(values) => {
                  const agrupados = {...agruparIdsPorTipo(values), raw: values};

                  setMembrosAtendimentos(agrupados);
                }}
              />
            </Form.Item>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Transferir imóveis',
      content: (
        <Col xs="12" className="w-100">
          <Col className="mb-2">
            <p className="heading-small text-muted mb-2">
              <small>O que fazer com os imóveis deste usuário?</small>
            </p>
            <Select
              className="w-100"
              key="imoveis"
              defaultValue={decisaoImoveis}
              onChange={(value) => setDecisaoImoveis(value)}>
              {decisaoImoveisOptions.map((option) => (
                <Option key={option.key}>{option.label}</Option>
              ))}
            </Select>
          </Col>
          <Row hidden={decisaoImoveis !== 'distribuir-para-membros'}>
            <Form.Item name="membrosSelecionadosImoveis" className="w-100">
              <SelectCorretoresOrGrupoCorretoresInput
                enableHelperTooltip
                enableTagRender
                defaultValue={membrosImoveis?.raw ?? []}
                placeholder={
                  membrosImoveis?.raw
                    ? 'Carregando membros selecionados...'
                    : 'Selecione membros...'
                }
                label={'Distribuir entre'}
                onChange={(values) => {
                  const agrupados = {...agruparIdsPorTipo(values), raw: values};

                  setMembrosImoveis(agrupados);
                }}
              />
            </Form.Item>
          </Row>
        </Col>
      ),
    },
    {
      title: 'Resumo',
      content: (
        <Col xs="12" className="w-100">
          <Row>
            <Col xs="12" className="w-100">
              <p className="heading-small text-muted mb-2">
                Os atendimentos deste usuário serão:{' '}
                <strong>
                  {
                    decisaoAtendimentosOptions.find(
                      (opt) => opt.key === decisaoAtendimento,
                    )?.finalMessage
                  }
                </strong>
              </p>
              <Row
                className="mb-2"
                hidden={decisaoAtendimento !== 'distribuir-para-membros'}>
                <TagMembros membros={membrosAtendimentos?.raw ?? []} />
              </Row>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="w-100">
              <Row>
                <p className="heading-small text-muted mb-2">
                  Os imoveis deste usuário serão:{' '}
                  <strong>
                    {
                      decisaoImoveisOptions.find(
                        (opt) => opt.key === decisaoImoveis,
                      )?.finalMessage
                    }
                  </strong>
                </p>
              </Row>
              <Row
                className="mb-2"
                hidden={decisaoImoveis !== 'distribuir-para-membros'}>
                <TagMembros membros={membrosImoveis?.raw ?? []} />
              </Row>
              <Row hidden={!isLoading}>
                <ProgressBarInativarUsuario usuarioId={usuarioId} />
              </Row>
            </Col>
          </Row>
        </Col>
      ),
    },
  ].filter((i) => !!i);

  const contentStyle = {
    marginTop: 16,
  };
  return (
    <Modal open={open} size="small">
      <Fragment>
        <Page loadPage={false}>
          <Card>
            <CardBody>
              <Col xs="12">
                <Form form={form} scrollToFirstError>
                  <Steps type="inline" current={current} items={steps} />
                  <div style={contentStyle}>{steps[current].content}</div>

                  <div
                    style={{
                      marginTop: 24,
                    }}>
                    <Row>
                      <Col xs="12">
                        <Row gutter={10}>
                          {current < steps.length - 1 && (
                            <Col>
                              <ButtonShared
                                onClick={nextStep}
                                icon
                                labelPosition="left"
                                size="medium"
                                fluid
                                color="violet">
                                <Row>
                                  <Icon name="angle double right" />
                                  Avançar
                                </Row>
                              </ButtonShared>
                            </Col>
                          )}
                          {current === steps.length - 1 && (
                            <Col>
                              <ButtonShared
                                onClick={() => onSave()}
                                icon
                                labelPosition="left"
                                size="medium"
                                fluid
                                color="violet"
                                isloading={`${isLoading}`}
                                loadtext={'Inativando...'}>
                                <Row>
                                  <Icon name="check" />
                                  Confirmar
                                </Row>
                              </ButtonShared>
                            </Col>
                          )}
                          {current > 0 && (
                            <Col>
                              <ButtonShared
                                icon
                                labelPosition="left"
                                size="medium"
                                disabled={isLoading}
                                fluid
                                onClick={() => previousStep()}>
                                <Row>
                                  <Icon name="reply" />
                                  Voltar
                                </Row>
                              </ButtonShared>
                            </Col>
                          )}
                          <Col>
                            <ButtonShared
                              icon
                              labelPosition="left"
                              size="medium"
                              color="red"
                              disabled={isLoading}
                              fluid
                              onClick={() => close() && clearState()}>
                              Cancelar
                            </ButtonShared>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </Col>
            </CardBody>
          </Card>
        </Page>
      </Fragment>
    </Modal>
  );
}

export default ModalInativarUsuario;
