import React from 'react';
import { Timeline, Collapse } from 'antd';
import moment from 'moment';

const { Panel } = Collapse;

const arrayAtendimentos = [
  { id: 0, title: 'Ligação', icon: <i className="fas fa-phone"></i> },
  { id: 1, title: 'Whatsapp', icon: <i className="fab fa-whatsapp"></i> },
  { id: 2, title: 'Ligação', icon: <i className="fas fa-phone"></i> },
  { id: 3, title: 'Email', icon: <i className="fas fa-envelope"></i> },
  { id: 4, title: 'Parceria', icon: <i className="fas fa-handshake"></i> },
  { id: 5, title: 'Pessoal', icon: <i className="fas fa-hands-helping"></i> },
  { id: 6, title: 'Sistema', icon: <i className="fas fa-desktop"></i> },
  { id: 7, title: 'Perdido', icon: <i className="fas fa-handshake"></i> },
  { id: 8, title: 'Reunião', icon: <i className="fa fa-handshake"></i> },
  { id: 9, title: 'SMS', icon: <i className="fa fa-comments"></i> },
  { id: 10, title: 'Nota', icon: <i className="fa fa-sticky-note"></i> },
  { id: 11, title: 'Apresentação', icon: <i className="fa fa-newspaper"></i> },
  { id: 12, title: 'Imóvel', icon: <i className="fa fa-home"></i> },
];

const renderTipoRelacionamento = (row) => (
  <span>
    {row?.tipoRelacionamento === 1 && `Atendimento via ${arrayAtendimentos.find((item) => item.id === row?.tipo)?.title.toLowerCase() || 'ligação'}`}
    {row?.tipoRelacionamento === 2 && 'Agendamento de visita'}
    {row?.tipoRelacionamento === 3 && 'Envio/Edição de Proposta'}
  </span>
);

const TimelineRelacionamentos = ({ dataRelacionamentos, handleDetalhesRelacionamento }) => {
  // Definir a quantidade inicial e o restante dos itens
  const initialItems = dataRelacionamentos.slice(0, 3);
  const remainingItems = dataRelacionamentos.slice(3);

  return (
    <Timeline style={{ paddingLeft: 20 }}>
      {/* Exibir os 3 primeiros itens */}
      {initialItems.map((row) => (
        ItemTime(row)
      ))}

      {/* Colapse para exibir os itens restantes */}
      {remainingItems.length > 0 && (
        <Collapse ghost>
          <Panel header={<small>Ver Mais</small>} key="1">
            {remainingItems.map((row) => (
              ItemTime(row)
            ))}
          </Panel>
        </Collapse>
      )}
    </Timeline>
  );

  function ItemTime(row) {
    return <Timeline.Item
      key={row.id}
      style={{ padding: '20px 0' }}
      dot={<div style={{
        backgroundColor: '#1D1F8C',
        color: '#fff',
        width: 38,
        height: 38,
        borderRadius: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        top: 30,
      }}>
        {arrayAtendimentos.find((item) => item.id === row.tipo)?.icon || <i className="fas fa-phone"></i>}
      </div>}
    >
      {/* Data e Hora em destaque */}
      <div style={{
        paddingLeft: 10,
        fontWeight: 'bold',
        fontSize: 18,
        color: '#1D1F8C',
        fontFamily: 'Montserrat',
        marginBottom: 8,
        display: 'flex',
        flexDirection: 'column',
      }}>
        <span>{row?.data ? moment(row.data).format('DD/MM/YYYY - HH:mm') : ''}</span>
        <span style={{
          fontSize: 12,
          color: '#555',
          fontWeight: 'normal',
          fontFamily: 'Montserrat',
        }}>Registrado em: {row?.dataCriacao ? moment(row.dataCriacao).format('DD/MM/YYYY - HH:mm') : ''}</span>
      </div>

      {/* Tipo do relacionamento */}
      <div style={{ fontSize: 16, fontFamily: 'Montserrat', marginBottom: 4 }}>
        <strong>Tipo:</strong> {renderTipoRelacionamento(row)}
      </div>

      {/* Imóvel */}
      {row?.imovel?.codImovel && <div style={{ fontSize: 16, fontFamily: 'Montserrat', marginBottom: 4 }}>
        <strong>Imóvel:</strong> {row?.imovel?.codImovel}
      </div>}

      {/* Descrição com Tooltip */}
      <div style={{ fontSize: 14, fontFamily: 'Montserrat', color: '#555', marginBottom: 4 }}>
        {row?.descricao?.length > 1000
          ? `${row.descricao.substring(0, 1000)}...`
          : row.descricao}
      </div>

      {/* Link para Ver Detalhes */}
      <div
        onClick={() => handleDetalhesRelacionamento(row)}
        style={{
          color: '#1D1F8C',
          cursor: 'pointer',
          fontFamily: 'Montserrat',
          fontSize: 16,
          textDecoration: 'underline',
        }}
      >
        Ver
      </div>
    </Timeline.Item>;
  }
};

export default TimelineRelacionamentos;
